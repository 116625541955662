import React from 'react';

import { OutboundWebhook } from '@m3ter-com/m3ter-api';

import { CrudDetails } from '@/components/common/crud/CrudDetails';
import { OutboundWebhookDetails } from '@/components/features/integrations/OutboundWebhookDetails/OutboundWebhookDetails';

export const OutboundWebhookDetailsRoute: React.FC = () => (
  <CrudDetails<OutboundWebhook> component={OutboundWebhookDetails} />
);
