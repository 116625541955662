import {
  EncodedIntegrationRunLogResponse,
  ExternalMapping,
  ExternalMappingEntityType,
  ExternalMappingExternalEntity,
  Id,
  IntegrationConfigSchema,
  IntegrationCredential,
  IntegrationCredentialRedirectURLResponse,
  UnsavedEntity,
} from '../types';

import { getOrganizationPath } from '../util/path';
import { get, post } from '../client';

export type CreateExternalMappingsData = Omit<
  UnsavedEntity<ExternalMapping>,
  'externalId' | 'm3terId'
> & {
  mappings: Array<{ externalId: string; m3terId: string }>;
  organizationId: string;
};

interface CreateIntegrationCredentialData {
  body: Record<string, any> & {
    name: string;
    type?: string;
  };
  destination: string;
  organizationId: string;
}

interface GetIntegrationCredentialRedirectURLData {
  destination: string;
  name: string;
  organizationId: string;
}

interface GetIntegrationsExternalEntitiesData {
  organizationId: string;
  externalSystem: string;
  m3terEntityType: ExternalMappingEntityType;
  integrationCredentialId?: string;
}

export const createExternalMappings = async (
  data: CreateExternalMappingsData
): Promise<Array<ExternalMapping>> => {
  const { mappings, organizationId, ...externalMappingsSettings } = data;
  const externalMappings = mappings.map<UnsavedEntity<ExternalMapping>>(
    (idMapping) => ({
      ...idMapping,
      ...externalMappingsSettings,
    })
  );
  return Promise.all(
    externalMappings.map(
      (externalMapping) =>
        post({
          body: externalMapping,
          path: getOrganizationPath('/externalmappings'),
          pathParams: { organizationId },
        }) as Promise<ExternalMapping>
    )
  );
};

export const createIntegrationCredential = (
  data: CreateIntegrationCredentialData
): Promise<IntegrationCredential> => {
  let pathSuffix: string | undefined;
  switch (data.destination.toLowerCase()) {
    case 'chargebee':
      pathSuffix = '/chargebee/chargebeeauth';
      break;
    case 'netsuite':
      pathSuffix = '/netsuite/netsuiteauth';
      break;
    case 'paddle':
      pathSuffix = '/paddle/paddleauth';
      break;
    case 'paddlesandbox':
      pathSuffix = '/paddlesandbox/paddleauth';
      break;
    case 'salesforce':
      pathSuffix = '/salesforce/clientcredentials';
      break;
    default:
    // No-op
  }

  if (!pathSuffix) {
    throw new Error(`Cannot create ${data.destination} credential`);
  }

  return post({
    body: data.body,
    path: getOrganizationPath(`/integrationauth${pathSuffix}`),
    pathParams: { organizationId: data.organizationId },
  }) as Promise<IntegrationCredential>;
};

export const getIntegrationConfigSchema = (
  organizationId: string
): Promise<IntegrationConfigSchema> =>
  get({
    path: getOrganizationPath('/integrationconfigs/available'),
    pathParams: { organizationId },
  }) as Promise<IntegrationConfigSchema>;

export const getIntegrationCredentialRedirectURL = (
  data: GetIntegrationCredentialRedirectURLData
): Promise<IntegrationCredentialRedirectURLResponse> =>
  get({
    path: getOrganizationPath('/integrationauth/:destination/oauth/authorize'),
    pathParams: {
      organizationId: data.organizationId,
      destination: data.destination.toLowerCase(),
    },
    queryParams: { name: data.name },
  }) as Promise<IntegrationCredentialRedirectURLResponse>;

export const getIntegrationsExternalEntities = (
  data: GetIntegrationsExternalEntitiesData
) =>
  get({
    path: getOrganizationPath(
      data.integrationCredentialId
        ? '/externalmappingconfiguration/values/:externalSystem/:m3terEntityType/:integrationCredentialId'
        : '/externalmappingconfiguration/values/:externalSystem/:m3terEntityType'
    ),
    pathParams: {
      organizationId: data.organizationId,
      externalSystem: data.externalSystem,
      m3terEntityType: data.m3terEntityType,
      integrationCredentialId: data.integrationCredentialId,
    },
  }) as Promise<Array<ExternalMappingExternalEntity>>;

export const getIntegrationRunLog = (organizationId: Id, runId: Id) =>
  get({
    path: getOrganizationPath('/integrationruns/logs/:runId'),
    pathParams: {
      organizationId,
      runId,
    },
  }) as Promise<EncodedIntegrationRunLogResponse>;
