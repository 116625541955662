import { spawn } from 'redux-saga/effects';

import accountsSaga from './accounts/accounts.saga';
import analyticsSaga from './analytics/analytics.saga';
import billingSaga from './billing/billing.saga';
import flagsSaga from './flags/flags.saga';
import pricingSaga from './pricing/pricing.saga';

export default function* featuresSaga() {
  yield spawn(accountsSaga);
  yield spawn(analyticsSaga);
  yield spawn(billingSaga);
  yield spawn(flagsSaga);
  yield spawn(pricingSaga);
}
