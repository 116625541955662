import { DateTimeISOString, Id } from '../entities';
import { AggregationType, UsageDataFrequency } from '../enums';

export enum UsageGroupType {
  Account = 'ACCOUNT',
  Dimension = 'DIMENSION',
  Time = 'TIME',
}

export enum UsageFieldType {
  Measure = 'MEASURE',
  Dimension = 'DIMENSION',
}

export interface MeterField {
  meterId: string;
  fieldCode: string;
}

export interface MeterFieldWithType extends MeterField {
  fieldType: UsageFieldType;
}

export interface UsageDimensionFilter extends MeterField {
  values: Array<string>;
}

export interface UsageAggregation extends MeterFieldWithType {
  function: AggregationType;
}

interface AccountGroup {
  groupType: UsageGroupType.Account;
}

interface DimensionGroup extends MeterField {
  groupType: UsageGroupType.Dimension;
}

interface TimeGroup {
  groupType: UsageGroupType.Time;
  frequency: UsageDataFrequency;
}

export type UsageGroup = AccountGroup | DimensionGroup | TimeGroup;

export interface UsageQuery {
  startDate: DateTimeISOString;
  endDate: DateTimeISOString;
  meterIds?: Array<Id>;
  accountIds?: Array<Id>;
  dimensionFilters?: Array<UsageDimensionFilter>;
  aggregations?: Array<UsageAggregation>;
  groups?: Array<UsageGroup>;
  limit?: number;
}

export interface UsageQueryResponseRow extends Record<string, any> {
  meterId: Id;
  m3terUUID?: Id;
  accountId?: Id;
}

export interface UsageQueryResponse {
  data: Array<UsageQueryResponseRow>;
  hasMoreData?: boolean;
}

export interface DimensionValuesRequestBody {
  startDate: DateTimeISOString;
  endDate: DateTimeISOString;
  meterId: Id;
  fieldCode: string;
  limit?: number;
}

export interface DimensionValuesResponse {
  values: Array<string>;
  hasMoreData?: boolean;
}
