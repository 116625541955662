import { useCallback, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import { DataType, DateTimeISOString, Id } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import useEntityUpdateMutation from '@/hooks/data/useEntityUpdateMutation';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';
import { NotificationOptions } from '@/hooks/util/useNotification';
import { dataTypeListAllQuery } from '@/queries/crud';

interface OverlappingAccountPlansParams {
  checkOverlap?: boolean;
  accountId?: Id;
  productId?: Id;
  startDate?: DateTimeISOString;
}

const useOverlappingAccountPlans = ({
  checkOverlap,
  accountId,
  productId,
  startDate,
}: OverlappingAccountPlansParams) => {
  const { t } = useTranslation();
  const pathParams = useOrgPathParams();
  const {
    data: accountPlans = [],
    refetch: refetchAccountPlans,
    isRefetching: isRefetchingAccountPlans,
  } = useQuery(
    dataTypeListAllQuery(
      {
        dataType: DataType.AccountPlan,
        pathParams,
        queryParams: { account: accountId, product: productId },
      },
      // We don't want to enable the overlap check until both the account and product are known.
      { enabled: !!checkOverlap && !!productId && !!accountId }
    )
  );

  // We know any plans in the data are active so it's just a case of checking
  // if they end before the entered start date. There should only be one active
  // plan per product/account combo but we still iterate with `find` just in case.
  const overlappingAccountPlan = useMemo(() => {
    if (!checkOverlap || !startDate) {
      return undefined;
    }
    return accountPlans.find(({ endDate }) => !endDate || endDate > startDate);
  }, [accountPlans, checkOverlap, startDate]);

  const successNotification = useMemo<NotificationOptions>(
    () => ({
      status: 'success',
      description: t('features:account.overlapPlanEnded'),
      duration: 5000,
    }),
    [t]
  );
  const failureNotification = useMemo<NotificationOptions>(
    () => ({
      status: 'error',
      description: t('features:account.overlapPlanEndedError'),
    }),
    [t]
  );

  const { isSaving: isUpdatingAccountPlan, updateEntity: updateAccountPlan } =
    useEntityUpdateMutation(DataType.AccountPlan);

  const onEndOverlappingAccountPlan = useCallback(() => {
    if (overlappingAccountPlan && startDate) {
      updateAccountPlan(
        {
          failureNotification,
          newEntity: {
            ...overlappingAccountPlan,
            endDate: startDate,
          },
          successNotification,
        },
        { onSuccess: () => refetchAccountPlans() }
      );
    }
  }, [
    overlappingAccountPlan,
    startDate,
    updateAccountPlan,
    failureNotification,
    successNotification,
    refetchAccountPlans,
  ]);

  return {
    overlappingAccountPlan,
    onEndOverlappingAccountPlan,
    isUpdatingAccountPlan,
    isRefetchingAccountPlans,
  };
};

export default useOverlappingAccountPlans;
