import React from 'react';

import { PlusIcon } from 'lucide-react';

import { Button, ButtonProps } from '@m3ter-com/ui-components';

import { useTranslation } from '../../../hooks/useTranslation';

interface FormAddRowButtonProps extends ButtonProps {
  buttonLabel?: string;
  maxRows?: number;
  onAdd: () => void;
}
export const FormAddRowButton: React.FC<FormAddRowButtonProps> = ({
  buttonLabel,
  maxRows = Infinity,
  onAdd,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <Button
      size="xs"
      leftIcon={<PlusIcon size={16} />}
      onClick={onAdd}
      alignSelf="start"
      {...props}
    >
      {buttonLabel ||
        `${t('common:add')} ${
          maxRows < Infinity
            ? t('forms:labels.maximumWithNumber', {
                max: maxRows,
              })
            : ''
        }`}
    </Button>
  );
};
