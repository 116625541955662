import React, { PropsWithChildren, useMemo } from 'react';

import {
  Alert as ChakraAlert,
  AlertDescription,
  AlertIcon,
  AlertProps as ChakraAlertProps,
  AlertTitle,
  Box,
  Spacer,
  AlertStatus,
} from '@chakra-ui/react';

import { Button, ButtonProps } from '../../controls/Button/Button';

export interface AlertProps extends ChakraAlertProps {
  alertTitle?: string;
  actionButtonText?: string;
  actionButtonProps?: ButtonProps;
}

const colourSchemes: Record<AlertStatus, string> = {
  error: 'red',
  info: 'blue',
  loading: 'blue',
  success: 'green',
  warning: 'yellow',
};

export const getColorScheme = (status: AlertStatus): string =>
  colourSchemes[status];

export const Alert = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<AlertProps>
>(
  (
    {
      alertTitle,
      actionButtonText,
      actionButtonProps,
      children,
      ...alertProps
    },
    ref
  ) => {
    const colorScheme = useMemo(
      () => (alertProps.status ? getColorScheme(alertProps.status) : undefined),
      [alertProps.status]
    );

    return (
      <ChakraAlert ref={ref} alignItems="center" {...alertProps}>
        <AlertIcon data-testid="default-alert-icon" />
        <Box w="100%">
          {!!alertTitle && (
            <AlertTitle data-testid="alert-title">{alertTitle}</AlertTitle>
          )}
          <AlertDescription whiteSpace="pre-line">{children}</AlertDescription>
        </Box>
        {!!actionButtonText && (
          <React.Fragment>
            <Spacer />
            <Button
              marginLeft={4}
              flexShrink={0}
              colorScheme={colorScheme}
              size="sm"
              {...actionButtonProps}
            >
              {actionButtonText}
            </Button>
          </React.Fragment>
        )}
      </ChakraAlert>
    );
  }
);
