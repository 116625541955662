import React from 'react';

import { Spinner } from '@chakra-ui/react';

import {
  DataType,
  CustomFieldDefaults,
  CustomFieldDefaultsKey,
} from '@m3ter-com/m3ter-api';

import useSingleton from '@/hooks/data/useSingleton';
import { FormCustomFieldsEditor } from '@/components/forms/FormCustomFieldsEditor';

export interface FormCustomFieldsEditorDefaultsProps {
  dataType: CustomFieldDefaultsKey;
}

export const FormCustomFieldsEditorDefaults: React.FC<
  FormCustomFieldsEditorDefaultsProps
> = ({ dataType }) => {
  const { isLoading, data } = useSingleton<CustomFieldDefaults>(
    DataType.OrganizationCustomFields
  );

  return isLoading ? (
    <Spinner size="sm" />
  ) : (
    <FormCustomFieldsEditor defaultCustomFields={data?.[dataType]} />
  );
};
