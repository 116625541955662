import React from 'react';

import { Badge } from '@chakra-ui/react';

import { Organization, OrganizationType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

interface OrganizationTypeBadgeProps {
  organization: Organization;
}

export const OrganizationTypeBadge: React.FC<OrganizationTypeBadgeProps> = ({
  organization,
}) => {
  const { t } = useTranslation();
  const isProductionOrg = organization.type === OrganizationType.Production;

  return (
    <Badge
      data-testid={`${organization.organizationName}-org-type-badge`}
      colorScheme={isProductionOrg ? 'green' : 'blue'}
    >
      {t(`features:organizations.type.${organization.type}`)}
    </Badge>
  );
};
