import { Id } from '@m3ter-com/m3ter-api';

import { isProd } from '@/config';

import { Feature } from './flags';

const ASSEMBLED_HQ_ORG_IDS = [
  'ebe253e8-5b32-42ca-ad46-7184bef6ca73',
  '1f1292a8-28e4-447c-8509-9e1295703b44',
  '998d9758-ce74-4d8c-8d5c-66e4ac493c34',
];

const M3TER_DATA_EXPORT_ORG_IDS = [
  '384a9800-1362-4051-af4d-6cf40809b626',
  '94b3614d-4367-40c3-82c9-a058536e1224',
  '0e0f67a4-1851-4b63-90d6-8ba883be9e33',
  '79baca2b-8d26-4746-b4f7-b6737a6eeeff',
  '7ee80633-9eba-497e-9622-f78c33bd8bdd',
  'dd5a5ce2-351a-44b0-b8a0-89bf981018bf',
  '396d788d-5174-4e8b-9d69-a41f4671fc33',
  '67375a79-2bb9-4450-9ea9-c3576146ad1a',
  '059d4e05-db17-432c-8061-d537805c4603',
  '6b4b25c4-b7c1-46dc-88df-51315bbf1366',
  '0dc75393-8382-4a83-ae3a-20d4ab853aaf',
  '2a3815a7-9af4-42bd-a80d-d7f1d6940af8',
];

const M3TER_FORECASTING_ORG_IDS = [
  '1eb50a40-87ec-49a8-bdde-6105ae75b9f9',
  '10729c4d-7f7b-4181-92a9-b89545d12187',
  '1a0797ed-76c6-4135-8155-5e644c6bbe6b',
  'c134aa85-2bd0-4a78-b222-06f557d538a3',
  'cea3e2a9-750b-45ff-856f-c68659a8322a',
  '847da24f-1529-401e-b2a5-48b267a19743',
  '8f8536c2-d11c-4ddf-940c-87ac82dac401',
  'ce808923-6ef5-4406-8389-e40a29eb054e',
];

const M3TER_REPORTING_ORG_IDS = [
  '965b4898-64e5-4ff9-860b-38b902d9c750',
  'df98936a-4c52-4677-87f9-8d86d7e9300d',
];

const M3TER_TRANSACTION_ORG_IDS = [
  '39d40463-0ae3-4bf8-acb0-9fa26f3e11d6',
  'eaf450ef-3eec-4355-8ab9-ac6f88a808b4',
  '83f09493-b628-48fa-bfc4-76eaf8e3e2b6',
  '396d788d-5174-4e8b-9d69-a41f4671fc33',
  '257065e1-5487-45d4-88ad-969bbad82564',
  'dbe43c6a-162e-4d5d-b306-b6180db496e5',
  '0761bf14-a04f-41e1-a80c-d3a774b16270',
  'dbe43c6a-162e-4d5d-b306-b6180db496e5',
];

const QUMULO_DATA_EXPORT_ORG_IDS = [
  '901d8b7f-ec9f-45e1-a60d-ae1438146ab1',
  '484c8cec-e42f-4727-a757-2e2e9870609c',
];

const REGAL_ORG_IDS = ['37d5da77-02af-4029-a232-bc0b446b9e7a'];

const SIFT_ORG_IDS = [
  '22207559-2ea6-430b-b76b-5e25769047c9',
  'b5349f0a-a540-4f03-b274-5cddd2f84c95',
  '18483df5-bdfa-452f-ad7e-5435a4aa3261',
];

const SNYK_DATA_EXPORT_ORG_IDS = [
  'd58aaae8-25ad-40f6-9548-1f4fe28fbca7',
  'b455fd7b-c5ed-474b-8ed5-bb550d797682',
  '20683717-1865-4cbc-8aed-3ce7dc1b8a3c',
];

const TRUELAYER_ORG_IDS = [
  '2b4f083f-0ec8-4ae2-9848-246de51ce42e',
  '5db4f934-c6ec-49d2-ad4b-0930246896c6',
  '49267f90-67f3-46cf-ab40-063c78f6ba71',
  '1935b25d-dd7d-46bc-b100-f561d1487120',
  '50574f81-f061-4a1f-ad69-f727d529ea6f',
  'f0c3b99f-0b3a-4a7f-a9bf-0f9085807967',
  'd39f8e76-11e4-45c5-ab38-02416140a706',
];

const UNBABEL_DATA_EXPORT_ORG_IDS = ['b82893eb-4070-4cc3-926f-c5820c99a63a'];

// This sets the default status of the flag per feature.
// If a feature is released to all orgs it would be true.
// If a feature is restricted to certain orgs it would be false
// and overriden in the `getFeatures` method, based on org ID.
const defaultFeatureFlags: Record<Feature, boolean> = {
  [Feature.CompoundAggregations]: true,
  [Feature.DataExplorerV2]: false,
  [Feature.DataExports]: false,
  [Feature.Forecasting]: false,
  [Feature.OutboundWebhooks]: true,
  [Feature.Notifications]: true,
  [Feature.Reports]: false,
  [Feature.Statements]: true,
  [Feature.TransactionPricing]: false,
};

// This sets whether or not the feature should be available
// to all orgs on non-prod.
const preProdFeatures: Record<Feature, boolean> = {
  [Feature.CompoundAggregations]: true,
  [Feature.DataExplorerV2]: true,
  [Feature.DataExports]: true,
  [Feature.Forecasting]: false,
  [Feature.OutboundWebhooks]: true,
  [Feature.Notifications]: true,
  [Feature.Reports]: true,
  [Feature.Statements]: true,
  [Feature.TransactionPricing]: false,
};

const isFeatureAllowedForOrg = (feature: Feature, orgId: string): boolean => {
  switch (feature) {
    case Feature.DataExplorerV2:
      return (
        SNYK_DATA_EXPORT_ORG_IDS.includes(orgId) ||
        M3TER_DATA_EXPORT_ORG_IDS.includes(orgId)
      );
    case Feature.DataExports:
      return (
        SNYK_DATA_EXPORT_ORG_IDS.includes(orgId) ||
        UNBABEL_DATA_EXPORT_ORG_IDS.includes(orgId) ||
        QUMULO_DATA_EXPORT_ORG_IDS.includes(orgId) ||
        M3TER_DATA_EXPORT_ORG_IDS.includes(orgId)
      );
    case Feature.Forecasting:
      return M3TER_FORECASTING_ORG_IDS.includes(orgId);
    case Feature.Reports:
      return (
        ASSEMBLED_HQ_ORG_IDS.includes(orgId) ||
        REGAL_ORG_IDS.includes(orgId) ||
        SIFT_ORG_IDS.includes(orgId) ||
        M3TER_REPORTING_ORG_IDS.includes(orgId)
      );
    case Feature.TransactionPricing:
      return (
        TRUELAYER_ORG_IDS.includes(orgId) ||
        M3TER_TRANSACTION_ORG_IDS.includes(orgId)
      );
    default: {
      return false;
    }
  }
};

export const getFeatures = (orgId: Id) =>
  Object.keys(defaultFeatureFlags).filter(
    (feature) =>
      (!isProd() && preProdFeatures[feature as Feature]) ||
      isFeatureAllowedForOrg(feature as Feature, orgId) ||
      defaultFeatureFlags[feature as Feature]
  );
