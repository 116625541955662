import React, { useMemo, useState } from 'react';

import sortBy from 'lodash/sortBy';
import {
  ButtonGroup,
  FormControl,
  FormLabel,
  useSteps,
} from '@chakra-ui/react';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  Button,
  FormStack,
  Input,
  Select,
  SelectOption,
  Stepper,
  Step,
} from '@m3ter-com/ui-components';
import { FormSection } from '@m3ter-com/console-core/components';

import { getAvailableIntegrationDestinationSchemas } from '@/util/integrations';
import { useIntegrationConfigSchemaContext } from '@/components/features/integrations/IntegrationConfigSchemaContext';

import { IntegrationCredentialCreateFormConfigureStep } from './IntegrationCredentialCreateFormConfigureStep';

export interface IntegrationCredentialCreateFormProps {
  onCancel: () => void;
}

export const IntegrationCredentialCreateForm: React.FC<
  IntegrationCredentialCreateFormProps
> = ({ onCancel }) => {
  const { t } = useTranslation();

  const { configSchema } = useIntegrationConfigSchemaContext();

  const { activeStep, goToNext, goToPrevious } = useSteps({ count: 2 });

  const [selectedDestination, setSelectedDestination] = useState<string | null>(
    null
  );
  const [name, setName] = useState<string>('');

  const destinationOptions = useMemo<Array<SelectOption>>(() => {
    // Some integration destinations may not have any auth options. In these cases, credentials
    // cannot be created for that destination, so we don't show them.
    const availableDestinationSchemas =
      getAvailableIntegrationDestinationSchemas(configSchema).filter(
        (destinationSchema) => !!destinationSchema.authConfigOptions?.length
      );

    return sortBy(
      availableDestinationSchemas.map((destinationSchema) => ({
        label: destinationSchema.displayName,
        value: destinationSchema.destination,
      })),
      'label'
    );
  }, [configSchema]);

  return (
    <React.Fragment>
      <Stepper index={activeStep} mb={6}>
        <Step title={t('features:integrations.selectSystemAndNameStepTitle')} />
        <Step title={t('features:integrations.configureStepTitle')} />
      </Stepper>

      {activeStep === 0 && (
        <FormStack alignItems="stretch">
          <FormSection
            heading={t('common:entityDetails', {
              entityName: t('features:integrations.credential'),
            })}
          >
            <FormControl isRequired>
              <FormLabel>{t('forms:labels.externalSystem')}</FormLabel>
              <Select
                isSearchable
                onChange={setSelectedDestination}
                options={destinationOptions}
                value={selectedDestination}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>{t('forms:labels.name')}</FormLabel>
              <Input
                onChange={(event) => {
                  setName(event.target.value);
                }}
                value={name}
              />
            </FormControl>
          </FormSection>
          <ButtonGroup>
            <Button
              intent="primary"
              isDisabled={!selectedDestination || !name}
              onClick={goToNext}
            >
              {t('common:next')}
            </Button>
            <Button variant="ghost" onClick={onCancel}>
              {t('common:cancel')}
            </Button>
          </ButtonGroup>
        </FormStack>
      )}
      {activeStep === 1 && !!selectedDestination && !!name && (
        <IntegrationCredentialCreateFormConfigureStep
          destination={selectedDestination}
          name={name}
          onBack={goToPrevious}
          onCancel={onCancel}
        />
      )}
    </React.Fragment>
  );
};
