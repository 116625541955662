import React from 'react';

import { Code, SimpleGrid } from '@chakra-ui/react';

import { DataType, OutboundWebhook } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { CopyToClipboard, KeyValue } from '@m3ter-com/ui-components';

import { DetailsCard } from '@/components/common/data/DetailsCard';

export interface OutboundWebhookDetailsProps {
  data: OutboundWebhook;
}

export const OutboundWebhookDetails: React.FC<OutboundWebhookDetailsProps> = ({
  data: webhook,
}) => {
  const { t } = useTranslation();

  return (
    <DetailsCard
      showEditButton
      data={webhook}
      dataType={DataType.OutboundWebhook}
      showMetadata={false}
      details={
        <SimpleGrid columns={4} gap={6} w="100%">
          <KeyValue label={t('forms:labels.name')} value={webhook.name} />
          <KeyValue label={t('forms:labels.code')} value={webhook.code} />
          <KeyValue
            label={t('forms:labels.description')}
            value={webhook.description || '-'}
          />
          <KeyValue
            label={t('forms:labels.url')}
            value={
              webhook.url && (
                <CopyToClipboard value={webhook.url}>
                  {webhook.url}
                </CopyToClipboard>
              )
            }
          />
          {!!webhook.credentials && (
            <React.Fragment>
              <KeyValue
                label={t('features:integrations.credentialType')}
                value={webhook.credentials.type}
              />
              <KeyValue
                label={t('features:integrations.apiKey')}
                value={
                  <CopyToClipboard value={webhook.credentials.apiKey}>
                    <Code>{webhook.credentials.apiKey}</Code>
                  </CopyToClipboard>
                }
              />
              <KeyValue
                label={t('features:integrations.apiSecret')}
                value={webhook.credentials.secret}
              />
            </React.Fragment>
          )}
        </SimpleGrid>
      }
    />
  );
};
