import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  createExternalMappings,
  CreateExternalMappingsData as CreateExternalMappingsAPIData,
  DataType,
  ExternalMappingConfig,
  Id,
} from '@m3ter-com/m3ter-api';

import { dataCreated } from '@/store/data/data';
import { dataTypeRetrieveQuery } from '@/queries/crud';
import useOrg from '@/hooks/data/crud/useOrg';
import useSingleton from '@/hooks/data/useSingleton';

export type ExternalMappingsCreateFormValues = Omit<
  CreateExternalMappingsAPIData,
  'integrationConfigId' | 'organizationId'
>;

type CreateExternalMappingsData = ExternalMappingsCreateFormValues & {
  returnPath?: string;
};

const useExternalMappingsCreate = (linkedIntegrationId?: Id) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { currentOrgId: organizationId } = useOrg();

  const {
    data: externalMappingConfig,
    error: externalMappingConfigError,
    isLoading: isLoadingExternalMappingConfig,
  } = useSingleton<ExternalMappingConfig>(DataType.ExternalMappingConfig);

  const {
    data: linkedIntegration,
    error: linkedIntegrationError,
    isLoading: isLoadingLinkedIntegration,
  } = useQuery(
    dataTypeRetrieveQuery(
      {
        dataType: DataType.Integration,
        id: linkedIntegrationId,
        pathParams: { organizationId },
      },
      { enabled: !!linkedIntegrationId }
    )
  );

  const {
    error: savingError,
    isPending: isSaving,
    mutate: onSave,
  } = useMutation({
    mutationFn: async ({
      returnPath: _returnPath,
      ...formValues
    }: CreateExternalMappingsData) =>
      createExternalMappings({
        ...formValues,
        integrationConfigId: linkedIntegrationId,
        organizationId,
      }),
    onSuccess: (newExternalMappings, createData) => {
      dispatch(dataCreated(DataType.ExternalMapping, newExternalMappings));
      queryClient.invalidateQueries({ queryKey: [DataType.ExternalMapping] });
      if (createData.returnPath) {
        navigate(createData.returnPath);
      }
    },
  });

  return {
    externalMappingConfig,
    externalMappingConfigError,
    isLoadingExternalMappingConfig,
    isLoadingLinkedIntegration,
    isSaving,
    linkedIntegration,
    linkedIntegrationError,
    onSave,
    savingError,
  };
};

export default useExternalMappingsCreate;
