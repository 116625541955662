import React, { useCallback, useMemo } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { Text } from '@chakra-ui/react';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  SpreadsheetInput,
  SpreadsheetInputRow,
} from '@m3ter-com/ui-components';
import { FormField, FormFieldset } from '@m3ter-com/console-core/components';

import {
  formatParameterLabel,
  IntegrationConfigParameterFieldProps,
} from './shared';

export type IntegrationConfigMapParameter = Record<string, string>;

export interface IntegrationConfigMapParameterEditorProps {
  name: string;
  options: Array<string>;
  value?: IntegrationConfigMapParameter;
  onChange: (newValue: IntegrationConfigMapParameter) => void;
}

export type FormIntegrationConfigMapParameterEditorProps = Omit<
  IntegrationConfigMapParameterEditorProps,
  'value' | 'onChange'
>;

const EMPTY_VALUE: IntegrationConfigMapParameter = {};

export const IntegrationConfigMapParameterEditor: React.FC<
  IntegrationConfigMapParameterEditorProps
> = ({ name, options, value = EMPTY_VALUE, onChange }) => {
  const { t } = useTranslation();

  const spreadsheetColumnLabels = useMemo<Array<string>>(
    () => [t('forms:labels.name'), t('forms:labels.value')],
    [t]
  );

  const spreadsheetRows = useMemo<Array<SpreadsheetInputRow>>(
    () =>
      options.map((option) => [
        { type: 'string', isDisabled: true, value: option },
        { type: 'string', value: value[option] },
      ]),
    [options, value]
  );

  const handleSpreadsheetRowsChange = useCallback(
    (newRows: Array<SpreadsheetInputRow>) =>
      onChange(
        newRows.reduce<IntegrationConfigMapParameter>((acc, row) => {
          const [keyCell, valueCell] = row;
          if (
            typeof keyCell?.value === 'string' &&
            typeof valueCell?.value === 'string'
          ) {
            acc[keyCell.value as string] = valueCell.value;
          }
          return acc;
        }, {})
      ),
    [onChange]
  );

  return (
    <SpreadsheetInput
      columnLabels={spreadsheetColumnLabels}
      enablePasting={false}
      id={name}
      onRowsChange={handleSpreadsheetRowsChange}
      rows={spreadsheetRows}
      addButtonLabel={t('common:add')}
    />
  );
};

const FormIntegrationConfigMapParameterEditor: React.FC<
  FormIntegrationConfigMapParameterEditorProps
> = ({ name, options }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <IntegrationConfigMapParameterEditor
          name={name}
          options={options}
          value={value}
          onChange={onChange}
        />
      )}
    />
  );
};

export const IntegrationConfigMapParameterField: React.FC<
  IntegrationConfigParameterFieldProps
> = ({ name, schema }) => {
  if (!schema.permittedValues?.length) {
    return null;
  }

  return (
    <FormFieldset legend={formatParameterLabel(schema.name)} width="100%">
      {schema.description && (
        <Text mb={2} variant="annotation">
          {schema.description}
        </Text>
      )}
      <FormField
        hideLabel
        name={name}
        label=""
        control={FormIntegrationConfigMapParameterEditor}
        options={schema.permittedValues}
      />
    </FormFieldset>
  );
};
