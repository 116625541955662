import {
  addDays,
  addMonths,
  compareAsc,
  compareDesc,
  format,
  isValid,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subQuarters,
  subWeeks,
  subYears,
} from 'date-fns';
import { toDate } from 'date-fns-tz';

import {
  AnalyticsJobTimePeriodType,
  DateTimeISOString,
} from '@m3ter-com/m3ter-api';

import { DateRange } from '../types';

import { clamp } from './number';

// Date formatting keys to be used with date-fns and date-fns-tz
// https://date-fns.org/v2.28.0/docs/format
export const longDateFormatKey = 'dd LLL yyyy';
export const longDateTimeFormatKey = 'dd LLL yyyy HH:mm:ss zzz';
export const monthYearFormatKey = 'LLL yyyy';
export const timeFormatKey = 'HH:mm:ss zzz';
export const timeZoneLessISOFormatKey = "yyyy-MM-dd'T'HH:mm:ss.SSS";
export const shortDateFormatKey = 'yyyy-LL-dd';
export const yearFormatKey = 'yyyy';

// Takes a date in the system's timezone and returns the same time / date in the given timezone
// E.g. You pass 1/1/2020 @ 5PM in GMT and a timeZone of America/New_York
// The date instance returned will be 1/1/2020 @ 5PM in New york (this would be 10PM in GMT)
export const copySystemDateInTimeZone = (
  d: string | Date,
  timeZone: string
): Date => {
  const date = typeof d === 'string' ? new Date(d) : d;

  return toDate(format(date, timeZoneLessISOFormatKey), { timeZone });
};

// When working with date strings that lack a timestamp, the JS Date
// constructor assumes the date means midnight in the system's timezone.
// In reality, we probably midnight on the given date in the org's timezone.
// To avoid formatting these types of date string incorrectly, we first need
// to construct a Date instance of the given date in the org's timezone.
// If there is a timestamp, we assume the time is correct and use the string
// as is.
export const hasTimestampRegex = /\d\d:\d\d/;
export const hasTimezoneRegex = /.+(z|(\+|-){1}\d\d:\d\d)/i;
export const getCleanDateInstance = (
  dirtyDate: string | Date,
  timeZone: string
) => {
  const isDateString = typeof dirtyDate === 'string';
  const hasTimestamp = isDateString && hasTimestampRegex.test(dirtyDate);
  const hasTimezone = isDateString && hasTimezoneRegex.test(dirtyDate);

  if (!isDateString || (hasTimestamp && hasTimezone)) {
    return new Date(dirtyDate);
  }

  const dateTimeString = hasTimestamp ? dirtyDate : `${dirtyDate}T00:00`;
  return copySystemDateInTimeZone(dateTimeString, timeZone);
};

// Takes two dates and an optional sorting direction (defaults to ascending) and
// returns a number indicating how the two dates should be sorted in compliance
// with Array.sort
export const getDatesSortOrder = (
  a: Date | string,
  b: Date | string,
  sortingDirection: 'asc' | 'desc' = 'asc'
): 0 | 1 | -1 => {
  const dateA = typeof a === 'string' ? new Date(a) : a;
  const dateB = typeof b === 'string' ? new Date(b) : b;

  if (!isValid(dateA) || !isValid(dateB)) {
    return 0;
  }

  const comparisonFunction =
    sortingDirection === 'asc' ? compareAsc : compareDesc;

  return comparisonFunction(dateA, dateB) as 0 | 1 | -1;
};

// Takes a start date and an end date (best if in ISO8601 format with timezone included to avoid
// TZ issues) and returns how far we are through that date range right now as a percentage.
// Optionally clamps the percentage between 0% and 100%.
export const getPercentageOfDateRangePassed = (
  startDate: string,
  endDate: string,
  imposeLimit = true
): number => {
  const nowMs = Date.now();
  const endDateMs = new Date(endDate).valueOf();
  const startDateMs = new Date(startDate).valueOf();
  const commitmentLengthMs = endDateMs - startDateMs;
  const timePassedMs = nowMs - startDateMs;
  const percentage = timePassedMs / commitmentLengthMs;
  return imposeLimit ? clamp(percentage, 0, 1) : percentage;
};

const getSystemDateValuesForTimePeriod = (
  period: AnalyticsJobTimePeriodType
): { startDate: Date; endDate: Date } => {
  const now = new Date(Date.now());
  const startOfToday = startOfDay(now);
  const startOfTomorrow = addDays(startOfToday, 1);

  switch (period) {
    case AnalyticsJobTimePeriodType.Yesterday:
      return {
        startDate: subDays(startOfToday, 1),
        endDate: startOfToday,
      };
    case AnalyticsJobTimePeriodType.PreviousWeek: {
      const mostRecentMonday = startOfWeek(now, { weekStartsOn: 1 });
      return {
        startDate: subWeeks(mostRecentMonday, 1),
        endDate: mostRecentMonday,
      };
    }
    case AnalyticsJobTimePeriodType.PreviousMonth: {
      const startOfCurrentMonth = startOfMonth(now);
      return {
        startDate: subMonths(startOfCurrentMonth, 1),
        endDate: startOfCurrentMonth,
      };
    }
    case AnalyticsJobTimePeriodType.PreviousQuarter: {
      const startOfCurrentQuarter = startOfQuarter(now);
      return {
        startDate: subQuarters(startOfCurrentQuarter, 1),
        endDate: startOfCurrentQuarter,
      };
    }
    case AnalyticsJobTimePeriodType.PreviousYear: {
      const startOfCurrentYear = startOfYear(now);
      return {
        startDate: subYears(startOfCurrentYear, 1),
        endDate: startOfCurrentYear,
      };
    }
    case AnalyticsJobTimePeriodType.LastSevenDays:
      return {
        startDate: subDays(startOfToday, 7),
        endDate: startOfToday,
      };
    case AnalyticsJobTimePeriodType.LastThirtyDays:
      return {
        startDate: subDays(startOfToday, 30),
        endDate: startOfToday,
      };
    case AnalyticsJobTimePeriodType.LastThirtyFiveDays:
      return {
        startDate: subDays(startOfToday, 35),
        endDate: startOfToday,
      };
    case AnalyticsJobTimePeriodType.LastNinetyDays:
      return {
        startDate: subDays(startOfToday, 90),
        endDate: startOfToday,
      };
    case AnalyticsJobTimePeriodType.LastOneHundredAndTwentyDays:
      return {
        startDate: subDays(startOfToday, 120),
        endDate: startOfToday,
      };
    case AnalyticsJobTimePeriodType.LastYear:
      return {
        startDate: subYears(startOfToday, 1),
        endDate: startOfToday,
      };
    case AnalyticsJobTimePeriodType.WeekToDate: {
      return {
        startDate: startOfWeek(now, { weekStartsOn: 1 }),
        endDate: startOfTomorrow,
      };
    }
    case AnalyticsJobTimePeriodType.MonthToDate:
      return {
        startDate: startOfMonth(now),
        endDate: startOfTomorrow,
      };
    case AnalyticsJobTimePeriodType.YearToDate:
      return {
        startDate: startOfYear(now),
        endDate: startOfTomorrow,
      };
    default:
      // Default to returning the values for AnalyticsJobTimePeriodType.Today
      return {
        startDate: startOfToday,
        endDate: startOfTomorrow,
      };
  }
};

// Takes a AnalyticsJobTimePeriodType enum value and a timeZone and returns a startDate and endDate
// for that time period
export const getDateValuesForTimePeriod = (
  period: AnalyticsJobTimePeriodType,
  timeZone: string
): { startDate: DateTimeISOString; endDate: DateTimeISOString } => {
  // We use date-fns to easily generate the start and end dates for any given time period
  // but this gives us them in the system's timezone so we then use date-fns-tz to create
  // date instances of those dates / times in the org's timezone.
  const systemDates = getSystemDateValuesForTimePeriod(period);
  const startDate = copySystemDateInTimeZone(
    systemDates.startDate,
    timeZone
  ).toISOString();
  const endDate = copySystemDateInTimeZone(
    systemDates.endDate,
    timeZone
  ).toISOString();

  return {
    startDate,
    endDate,
  };
};

// Takes a start date and an end date and checks that
// 1. Both dates are valid
// 2. The start date is before the end date
export const isDateRangeValid = (
  start: Date | string,
  end: Date | string
): boolean => {
  const startDate = typeof start === 'string' ? new Date(start) : start;
  const endDate = typeof end === 'string' ? new Date(end) : end;

  if (!isValid(startDate) || !isValid(endDate)) {
    return false;
  }

  return compareAsc(startDate, endDate) === -1;
};

export const isInDateRange = (
  value: Date | string,
  start: Date | string,
  end: Date | string
): boolean => {
  const date = typeof value === 'string' ? new Date(value) : value;
  const startDate = typeof start === 'string' ? new Date(start) : start;
  const endDate = typeof end === 'string' ? new Date(end) : end;

  return date >= startDate && date < endDate;
};

export const getDateValuesForDateRange = (
  range: DateRange,
  timeZone: string
): { start: DateTimeISOString; end: DateTimeISOString } => {
  const now = new Date(Date.now());
  const startOfThisMonth = startOfMonth(now);

  let start;
  let end;

  switch (range) {
    case DateRange.Today:
      start = startOfDay(now);
      end = addDays(start, 1);
      break;
    case DateRange.Yesterday:
      end = startOfDay(now);
      start = subDays(end, 1);
      break;
    case DateRange.Last7Days:
      end = startOfDay(now);
      start = subDays(end, 7);
      break;
    case DateRange.Last30Days:
      end = startOfDay(now);
      start = subDays(end, 30);
      break;
    case DateRange.Last60Days:
      end = startOfDay(now);
      start = subDays(end, 60);
      break;
    case DateRange.Last90Days:
      end = startOfDay(now);
      start = subDays(end, 90);
      break;
    case DateRange.ThisMonth:
      start = startOfThisMonth;
      end = addMonths(startOfThisMonth, 1);
      break;
    case DateRange.LastMonth: {
      start = subMonths(startOfThisMonth, 1);
      end = startOfThisMonth;
      break;
    }
    default:
      throw new Error(`Invalid date range: ${range}`);
  }

  return {
    start: copySystemDateInTimeZone(start, timeZone).toISOString(),
    end: copySystemDateInTimeZone(end, timeZone).toISOString(),
  };
};
