import React, { useCallback, useMemo } from 'react';

import { DataType, Id, OutboundWebhook } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import {
  ColumnDefinition,
  EntityMultiSelectModal,
} from '@/components/common/data/EntitySelectModal';

export interface LinkWebhooksToNotificationRuleModalProps {
  linkedWebhooks: Array<OutboundWebhook>;
  onClose: () => void;
  onLink: (webhookIds: Array<Id>) => void;
}

const EMPTY_IDS_ARRAY: Array<Id> = [];

export const LinkWebhooksToNotificationRuleModal: React.FC<
  LinkWebhooksToNotificationRuleModalProps
> = ({ linkedWebhooks, onClose, onLink }) => {
  const { t } = useTranslation();

  const columnDefinitions = useMemo<Array<ColumnDefinition<OutboundWebhook>>>(
    () => [
      { id: 'name', header: t('forms:labels.name'), accessor: 'name' },
      { id: 'name', header: t('forms:labels.url'), accessor: 'url' },
    ],
    [t]
  );

  const isWebhookAlreadyLinked = useCallback(
    (webhook: OutboundWebhook) =>
      linkedWebhooks.some((linkedWebhook) => linkedWebhook.id === webhook.id),
    [linkedWebhooks]
  );

  return (
    <EntityMultiSelectModal<OutboundWebhook>
      isOpen
      columns={columnDefinitions}
      dataType={DataType.OutboundWebhook}
      isItemDisabled={isWebhookAlreadyLinked}
      onClose={onClose}
      onConfirm={onLink}
      selected={EMPTY_IDS_ARRAY}
    />
  );
};
