import React, { useCallback, useMemo } from 'react';

import { useSelector } from 'react-redux';
import {
  ButtonGroup,
  Card,
  CardBody,
  Heading,
  Tooltip,
} from '@chakra-ui/react';

import { DataType, Account, Bill } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button, CardActionsHeader } from '@m3ter-com/ui-components';

import { OtherListIds } from '@/types/lists';

import useBillsList from '@/hooks/features/billing/useBillsList';
import { isBillLocked } from '@/util/billing';
import { selectBillConfig } from '@/store/app/bootstrap/bootstrap';
import {
  CrudList,
  CrudListFooter,
  CrudListHeader,
} from '@/components/common/crud/CrudList';
import {
  RunBillingFormValues,
  RunBillingModal,
} from '@/components/features/billing/RunBillingModal';
import { EntitiesBulkDeleteAction } from '@/components/common/data/EntitiesBulkDeleteAction';
import { AccountBillsTable } from '@/components/features/accounts/AccountBillsTable';

export interface AccountBillsProps {
  account: Account;
}

export const AccountBills: React.FC<AccountBillsProps> = ({ account }) => {
  const { t } = useTranslation();
  const billConfig = useSelector(selectBillConfig);

  const accountId = account.id;
  const isChildAccount = !!account?.parentAccountId;

  const {
    selectedBillIds,
    includeFrequency,
    isGeneratingBills,
    isReclaclulatingSomeSelectedBill,
    isRunBillingModalOpen,
    isSomeSelectedBillLocked,
    closeRunBillingModal,
    openRunBillingModal,
    recalculateSelectedBills,
    runBilling,
    setSelectedBillIds,
    clearSelectedBillIds,
  } = useBillsList();

  const billsParams = useMemo(
    () => ({ accountId, excludeLineItems: true }),
    [accountId]
  );

  const isBillSelectable = useCallback(
    (bill: Bill) => !isBillLocked(bill, billConfig),
    [billConfig]
  );

  const onRunBillingSubmit = useCallback(
    (data: RunBillingFormValues) => {
      runBilling(
        data.externalInvoiceDate,
        data.frequency,
        [accountId],
        data.targetCurrency
      );
      closeRunBillingModal();
    },
    [closeRunBillingModal, runBilling, accountId]
  );

  return (
    <React.Fragment>
      <Card>
        <CardActionsHeader
          actions={
            <Tooltip
              label={
                isChildAccount
                  ? t('features:billing.childAccountBillCalculationWarning')
                  : ''
              }
            >
              <Button
                size="sm"
                onClick={openRunBillingModal}
                isLoading={isGeneratingBills}
                isDisabled={isChildAccount}
              >
                {t('features:billing.runBilling')}
              </Button>
            </Tooltip>
          }
        >
          <Heading size="md">{t('common:bills')}</Heading>
        </CardActionsHeader>
        <CardBody>
          <CrudList<Bill>
            dataType={DataType.Bill}
            listId={OtherListIds.AccountBills}
            params={billsParams}
          >
            <CrudListHeader />
            <AccountBillsTable
              isItemSelectable={isBillSelectable}
              onSelectedItemsChange={setSelectedBillIds}
              rowSelectionMode="multi"
              selectedItemIds={selectedBillIds}
            />
            <CrudListFooter>
              <ButtonGroup>
                <Tooltip
                  label={
                    isChildAccount
                      ? t('features:billing.childAccountBillCalculationWarning')
                      : ''
                  }
                >
                  <Button
                    isDisabled={
                      isChildAccount ||
                      selectedBillIds.length === 0 ||
                      isReclaclulatingSomeSelectedBill
                    }
                    isLoading={isReclaclulatingSomeSelectedBill}
                    onClick={recalculateSelectedBills}
                  >
                    {t('features:billing.recalculateSelected')}
                  </Button>
                </Tooltip>
                <EntitiesBulkDeleteAction
                  dataType={DataType.Bill}
                  isDisabled={isSomeSelectedBillLocked}
                  selectedItems={selectedBillIds}
                  clearSelectedItems={clearSelectedBillIds}
                />
              </ButtonGroup>
            </CrudListFooter>
          </CrudList>
        </CardBody>
      </Card>
      <RunBillingModal
        includeFrequency={includeFrequency}
        isOpen={isRunBillingModalOpen}
        onClose={closeRunBillingModal}
        onSubmit={onRunBillingSubmit}
      />
    </React.Fragment>
  );
};
