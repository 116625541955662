import { create, each, enforce, omitWhen, test } from 'vest';
import i18next from 'i18next';

import { Commitment } from '@m3ter-com/m3ter-api';

const suite = create('commitment', (data: Partial<Commitment>) => {
  test(
    'startDate',
    i18next.t('forms:validations.common.startDateRequired'),
    () => {
      enforce(data.startDate).isNotEmpty();
    }
  );

  test('endDate', i18next.t('forms:validations.common.endDateRequired'), () => {
    enforce(data.endDate).isNotEmpty();
  });

  test(
    'accountingProductId',
    i18next.t('forms:validations.commitment.accountingProductRequired'),
    () => {
      enforce(data.accountingProductId).isNotEmpty();
    }
  );

  test(
    'currency',
    i18next.t('forms:validations.common.currencyRequired'),
    () => {
      enforce(data.currency).isNotEmpty();
    }
  );

  test('amount', i18next.t('forms:validations.common.amountRequired'), () => {
    enforce(data.amount).isNotEmpty();
  });

  test(
    'amountPrePaid',
    i18next.t(
      'forms:validations.commitment.amountPrePaidLessThanOrEqualAmount'
    ),
    () => {
      enforce(data.amountPrePaid).lessThanOrEquals(data.amount ?? 0);
    }
  );

  const isFullyPrepaid = (data.amountPrePaid ?? 0) >= (data.amount ?? 0);
  const isBilledWithPlan = !!data.billingPlanId;
  const isBilledOnSchedule = !!data.feeDates?.length;

  // Fee dates tests.
  omitWhen(isFullyPrepaid || isBilledWithPlan, () => {
    each(data.feeDates ?? [], (feeDate, index) => {
      test(
        `feeDates.${index}.date`,
        i18next.t('forms:validations.common.dateRequired'),
        () => {
          enforce(feeDate.date).isNotEmpty();
        }
      );
      test(
        `feeDates.${index}.amount`,
        i18next.t('forms:validations.common.amountRequired'),
        () => {
          enforce(feeDate.amount).isNotEmpty();
        }
      );
      test(
        `feeDates.${index}.servicePeriodStartDate`,
        i18next.t(
          'forms:validations.commitment.servicePeriodStartDateRequired'
        ),
        () => {
          enforce(feeDate.servicePeriodStartDate).isNotEmpty();
        }
      );
      test(
        `feeDates.${index}.servicePeriodEndDate`,
        i18next.t('forms:validations.commitment.servicePeriodEndDateRequired'),
        () => {
          enforce(feeDate.servicePeriodEndDate).isNotEmpty();
        }
      );
    });
  });

  // Billing field tests.
  omitWhen(isFullyPrepaid || isBilledOnSchedule, () => {
    // Billing field tests.
    test(
      'billingPlanId',
      i18next.t('forms:validations.commitment.billingPlanRequired'),
      () => {
        enforce(data.billingPlanId).isNotEmpty();
      }
    );

    test(
      'billingInterval',
      i18next.t('forms:validations.commitment.billingIntervalRequired'),
      () => {
        enforce(data.billingInterval).isNotEmpty();
      }
    );

    test(
      'billingOffset',
      i18next.t('forms:validations.commitment.billingOffsetRequired'),
      () => {
        enforce(data.billingOffset).greaterThanOrEquals(0);
        enforce(data.billingOffset).lessThanOrEquals(364);
      }
    );
  });
});

export default suite;
