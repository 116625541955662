import {
  DataExplorerUsageDataRequestBody,
  DataExplorerUsageDataResponse,
  DataExplorerUsageMeterDimensionsRequestBody,
  DataExplorerUsageMeterDimensionsResponse,
  DataExplorerUsageMetersResponse,
  DimensionValuesRequestBody,
  DimensionValuesResponse,
  Id,
  QueryParams,
  UsageQuery,
  UsageQueryResponse,
} from '../types';

import { getOrganizationPath } from '../util/path';
import { get, post } from '../client';

export const getDataExplorerUsageData = (
  organizationId: Id,
  body: DataExplorerUsageDataRequestBody
) =>
  post({
    path: getOrganizationPath('/dataexplorer/usagedata'),
    pathParams: { organizationId },
    body,
  }) as Promise<DataExplorerUsageDataResponse>;

export const getDataExplorerUsageMeters = (
  organizationId: Id,
  queryParams?: QueryParams
) =>
  get({
    path: getOrganizationPath('/dataexplorer/meters'),
    pathParams: { organizationId },
    queryParams,
  }) as Promise<DataExplorerUsageMetersResponse>;

export const getDataExplorerUsageMeterDimensionValues = (
  organizationId: Id,
  body: DataExplorerUsageMeterDimensionsRequestBody
) =>
  post({
    path: getOrganizationPath('/dataexplorer/mergedmeterdimensions'),
    pathParams: { organizationId },
    body,
  }) as Promise<DataExplorerUsageMeterDimensionsResponse>;

export const queryUsage = (organizationId: Id, body: UsageQuery) =>
  post({
    path: getOrganizationPath('/usage/query'),
    pathParams: { organizationId },
    body,
  }) as Promise<UsageQueryResponse>;

export const getDimensionValues = (
  organizationId: Id,
  body: DimensionValuesRequestBody
) =>
  post({
    path: getOrganizationPath('/usage/dimensionvalues'),
    pathParams: { organizationId },
    body,
  }) as Promise<DimensionValuesResponse>;
