import React from 'react';

import { OutboundWebhook } from '@m3ter-com/m3ter-api';

import { CrudCreate } from '@/components/common/crud/CrudCreate';
import { OutboundWebhookForm } from '@/components/features/integrations/OutboundWebhookForm/OutboundWebhookForm';

export const OutboundWebhookCreateRoute: React.FC = () => (
  <CrudCreate<OutboundWebhook> form={OutboundWebhookForm} />
);
