import React, { useMemo } from 'react';

import {
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  IconButton,
  Link,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { RefreshCwIcon } from 'lucide-react';

import { DataType, Id, OutboundWebhook } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  Button,
  InteractiveColumnDefinition,
  InteractiveTable,
} from '@m3ter-com/ui-components';

import { getCrudRouteName, CrudRouteType } from '@/routes/crud';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import { NamedLink } from '@/components/common/navigation/NamedLink';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink';
import { LoadingErrorContentSwitch } from '@/components/common/errors/LoadingErrorContentSwitch';
import { ActiveStatusBadge } from '@/components/common/data/ActiveStatusBadge';

import useNotificationRuleLinkedWebhooks from './useNotificationRuleLinkedWebhooks';
import { LinkWebhooksToNotificationRuleModal } from './LinkWebhooksToNotificationRuleModal';

interface NotificationRuleLinkedWebhooksProps {
  notificationRuleId: Id;
}

export const NotificationRuleLinkedWebhooks: React.FC<
  NotificationRuleLinkedWebhooksProps
> = ({ notificationRuleId }) => {
  const { t } = useTranslation();
  const webhookNamings = useEntityNamings(DataType.OutboundWebhook);

  const {
    error,
    isLinkingWebhooks,
    isLoading,
    linkedWebhooks,
    onLinkWebhooks,
    refreshLinkedWebhooks,
  } = useNotificationRuleLinkedWebhooks(notificationRuleId);

  const columnDefinitions = useMemo<
    Array<InteractiveColumnDefinition<OutboundWebhook>>
  >(
    () => [
      {
        id: 'name',
        header: t('forms:labels.name'),
        accessor: (item) => (
          <Link
            as={CrudDetailsLink}
            dataType={DataType.OutboundWebhook}
            id={item.id}
          >
            {item.name} <ActiveStatusBadge active={item.active} />
          </Link>
        ),
      },
      {
        id: 'url',
        accessor: 'url',
        header: t('forms:labels.url'),
      },
    ],
    [t]
  );

  const {
    isOpen: isLinkWebhooksModalOpen,
    onOpen: onOpenLinkWebhooksModal,
    onClose: onCloseLinkWebhooksModal,
  } = useDisclosure();

  return (
    <Card>
      <CardHeader>
        <Flex justify="space-between" alignItems="center">
          <Heading size="md">{webhookNamings.plural}</Heading>
          <ButtonGroup size="sm">
            <IconButton
              aria-label={t('common:refresh')}
              icon={<RefreshCwIcon size={16} />}
              isDisabled={isLoading}
              onClick={refreshLinkedWebhooks}
            />
            <Button
              isDisabled={isLinkingWebhooks}
              isLoading={isLinkingWebhooks}
              onClick={onOpenLinkWebhooksModal}
            >
              {t('features:webhooks.linkWebhooks')}
            </Button>
            <Button
              as={NamedLink}
              name={getCrudRouteName(
                DataType.OutboundWebhook,
                CrudRouteType.Create
              )}
            >
              {t('forms:buttons.createEntity', {
                entityName: webhookNamings.singularLower,
              })}
            </Button>
          </ButtonGroup>
        </Flex>
      </CardHeader>
      <CardBody>
        <LoadingErrorContentSwitch
          isLoading={isLoading}
          error={error}
          showContentOnError
        >
          <InteractiveTable
            columnDefinitions={columnDefinitions}
            emptyContent={
              <Text>
                {t('errors:generic.noDataToDisplay', {
                  entityName: webhookNamings.pluralLower,
                })}
              </Text>
            }
            items={linkedWebhooks}
            paginationType="client"
          />
        </LoadingErrorContentSwitch>
      </CardBody>
      {!!isLinkWebhooksModalOpen && (
        <LinkWebhooksToNotificationRuleModal
          linkedWebhooks={linkedWebhooks}
          onClose={onCloseLinkWebhooksModal}
          onLink={onLinkWebhooks}
        />
      )}
    </Card>
  );
};
