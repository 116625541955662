import { ComponentMultiStyleConfig } from '@chakra-ui/react';

const parts = ['container', 'title', 'description', 'icon', 'spinner'];

const alert: ComponentMultiStyleConfig = {
  parts,
  baseStyle: {
    container: {
      borderRadius: 'md',
    },
  },
};

export default alert;
