import React, { useEffect } from 'react';

import { Outlet, ScrollRestoration } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorBoundary } from '@sentry/react';
import { Box } from '@chakra-ui/react';

import { LoadingScreen } from '@m3ter-com/ui-components';

import {
  bootstrapApp,
  selectMinorBootstrapErrors,
} from '@/store/app/bootstrap/bootstrap';
import useAuth from '@/hooks/auth/useAuth';
import useUserflow from '@/hooks/util/useUserflow';
import { GenericErrorMessage } from '@/components/common/errors/GenericErrorMessage';
import { ErrorAlerts } from '@/components/common/errors/ErrorAlerts';
import { Notifications } from '@/components/common/notifications/Notifications';
import { TermsModal } from '@/components/features/access/user/TermsModal';

export const RootRoute: React.FC = () => {
  const dispatch = useDispatch();

  const { isSigningOut, isAuthenticated, user } = useAuth();
  const minorBootstrapErrors = useSelector(selectMinorBootstrapErrors);

  useUserflow();

  useEffect(() => {
    dispatch(bootstrapApp());
  }, [dispatch]);

  const content =
    // eslint-disable-next-line no-nested-ternary
    isAuthenticated && user && !user.lastAcceptedTermsAndConditions ? (
      <TermsModal />
    ) : isSigningOut ? (
      <LoadingScreen />
    ) : (
      <React.Fragment>
        <ErrorAlerts errors={minorBootstrapErrors} />
        <Notifications />
        <Outlet />
      </React.Fragment>
    );

  return (
    <ErrorBoundary
      fallback={({ error, componentStack }) => {
        return (
          <Box p={8} h="100vh" overflowY="scroll">
            <GenericErrorMessage
              error={error}
              componentStack={componentStack}
            />
          </Box>
        );
      }}
    >
      {/* Track scroll positions based only on the URL path, ignoring query parameters. */}
      <ScrollRestoration getKey={({ pathname }) => pathname} />
      {content}
    </ErrorBoundary>
  );
};
