import {
  Id,
  UsageAnomaliesRequestBody,
  UsageAnomaliesResponse,
  UsageForecastRequestBody,
  UsageForecastResponse,
  UsageHealthReportRequestBody,
  UsageHealthReportResponse,
  UsageMonitoringRequestBody,
  UsageMonitoringResponse,
} from '../types';

import { getOrganizationPath } from '../util/path';
import { post } from '../client';

export const getUsageAnomalies = (
  organizationId: Id,
  body: UsageAnomaliesRequestBody
) =>
  post({
    body,
    path: getOrganizationPath('/usageforecasts/anomalies'),
    pathParams: { organizationId },
  }) as Promise<UsageAnomaliesResponse>;

export const getUsageForecast = (
  organizationId: Id,
  body: UsageForecastRequestBody
): Promise<UsageForecastResponse> =>
  post({
    body,
    path: getOrganizationPath('/usageforecasts'),
    pathParams: { organizationId },
  }) as Promise<UsageForecastResponse>;

export const getUsageHealthReport = (
  organizationId: Id,
  body?: UsageHealthReportRequestBody
) =>
  post({
    body,
    path: getOrganizationPath('/usageforecasts/healthscores'),
    pathParams: { organizationId },
  }) as Promise<UsageHealthReportResponse>;

export const getUsageMonitoringData = (
  organizationId: Id,
  body: UsageMonitoringRequestBody
) =>
  post({
    body,
    path: getOrganizationPath('/usageforecasts/monitoring'),
    pathParams: { organizationId },
  }) as Promise<UsageMonitoringResponse>;
