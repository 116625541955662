import { useCallback, useMemo, useState } from 'react';

import sortBy from 'lodash/sortBy';

import { Meter } from '@m3ter-com/m3ter-api';

import { useQueryBuilder } from '@/components/features/usage/query-builder/UsageQueryBuilder/UsageQueryBuilder';

const filterMeters = (
  meters: Array<Meter>,
  searchTerm: string
): Array<Meter> => {
  const lcSearchTerm = searchTerm.toLowerCase();

  return meters.filter((meter) => {
    const name = meter.name.toLowerCase();
    return (
      name.startsWith(lcSearchTerm) ||
      name.split(' ').some((word) => word.startsWith(lcSearchTerm))
    );
  });
};

const useUsageQueryBuilderMeters = () => {
  const { meters } = useQueryBuilder();

  const sortedMeters = useMemo(() => sortBy(meters, 'name'), [meters]);

  // Filtering.

  const [searchTerm, setSearchTerm] = useState<string>('');

  const clearSearchTerm = useCallback(() => {
    setSearchTerm('');
  }, []);

  const filteredSortedMeters = useMemo(
    () =>
      searchTerm !== '' ? filterMeters(sortedMeters, searchTerm) : sortedMeters,
    [searchTerm, sortedMeters]
  );

  return {
    meters,
    filteredSortedMeters,
    searchTerm,
    setSearchTerm,
    clearSearchTerm,
  };
};

export default useUsageQueryBuilderMeters;
