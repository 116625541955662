import React, { ElementType } from 'react';

import { HStack, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { EllipsisIcon } from 'lucide-react';

import { Button, ButtonProps } from '../Button/Button';
import { IconButton } from '../Button/IconButton';

export interface Action extends Record<string, any> {
  key: string;
  label: string;
  as?: ElementType;
}

export interface DropdownButtonProps extends ButtonProps {
  actions: Array<Action>;
  maxButtons?: number;
}

interface ActionButtonProps extends ButtonProps {
  action: Action;
}

const DEFAULT_MAX_BUTTONS = 2;

const ActionButton: React.FC<ActionButtonProps> = ({ action, ...props }) => {
  const { label, ...rest } = action;

  return (
    <Button {...props} {...rest} key={action.key}>
      {label}
    </Button>
  );
};

export const DropdownButton: React.FC<DropdownButtonProps> = ({
  actions,
  maxButtons = DEFAULT_MAX_BUTTONS,
  ...buttonProps
}) => {
  if (actions.length === 0) {
    return null;
  }

  if (actions.length <= maxButtons) {
    // Only need standard buttons.
    return (
      <HStack>
        {actions.map((action) => (
          <ActionButton key={action.key} action={action} {...buttonProps} />
        ))}
      </HStack>
    );
  }

  const [primaryAction, ...otherActions] = actions;

  return (
    <Menu>
      <HStack>
        <ActionButton action={primaryAction} {...buttonProps} />
        <MenuButton
          as={IconButton}
          aria-label="More"
          icon={<EllipsisIcon />}
          {...buttonProps}
        />
      </HStack>
      <MenuList>
        {otherActions.map((action) => {
          const { key, label, ...rest } = action;
          return (
            <MenuItem key={key} {...rest}>
              {label}
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};
