import { create, enforce, test } from 'vest';
import i18next from 'i18next';

import { OutboundWebhook } from '@m3ter-com/m3ter-api';

const suite = create('outbound-webhook', (data: Partial<OutboundWebhook>) => {
  test('name', i18next.t('forms:validations.common.nameRequired'), () => {
    enforce(data.name).isNotEmpty();
  });

  test('code', i18next.t('forms:validations.common.codeRequired'), () => {
    enforce(data.code).isNotEmpty();
  });

  test(
    'description',
    i18next.t('forms:validations.common.descriptionRequired'),
    () => {
      enforce(data.description).isNotEmpty();
    }
  );

  test('url', i18next.t('forms:validations.webhook.urlRequired'), () => {
    enforce(data.url).isNotEmpty();
  });

  test(
    'credentials.type',
    i18next.t('forms:validations.webhook.credentialsTypeRequired'),
    () => {
      enforce(data.credentials?.type).isNotEmpty();
    }
  );

  test(
    'credentials.apiKey',
    i18next.t('forms:validations.webhook.credentialsApiKeyRequired'),
    () => {
      enforce(data.credentials?.apiKey).isNotEmpty();
    }
  );

  test(
    'credentials.secret',
    i18next.t('forms:validations.webhook.credentialsApiSecretRequired'),
    () => {
      enforce(data.credentials?.secret).isNotEmpty();
    }
  );
});

export default suite;
