import React from 'react';

import { BellIcon, CircleAlertIcon } from 'lucide-react';

import { DataType } from '@m3ter-com/m3ter-api';
import { Navigation } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { CrudRouteType, getCrudRouteName } from '@/routes/crud';
import useFeatureFlags, { Feature } from '@/hooks/util/useFeatureFlags';
import { NamedNavLink } from '@/components/common/navigation/NamedNavLink';

export interface EventsNavigationProps {}

export const EventsNavigation: React.FC<EventsNavigationProps> = () => {
  const { t } = useTranslation();
  const { isFeatureEnabled } = useFeatureFlags();

  return (
    <Navigation>
      <NamedNavLink
        name={getCrudRouteName(DataType.Alert, CrudRouteType.List)}
        icon={<CircleAlertIcon />}
      >
        {t('common:alerts')}
      </NamedNavLink>
      {isFeatureEnabled(Feature.Notifications) && (
        <NamedNavLink name="notification-rules" icon={<BellIcon />}>
          {t('common:notificationRules')}
        </NamedNavLink>
      )}
    </Navigation>
  );
};
