import React, { useEffect, useMemo, useRef } from 'react';

import { Badge, useColorModeValue } from '@chakra-ui/react';

import { Organization } from '@m3ter-com/m3ter-api';
import { Button, ButtonProps } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { NamedLink } from '@/components/common/navigation/NamedLink';
import { OrganizationTypeBadge } from '@/components/features/organization/OrganizationTypeBadge/OrganizationTypeBadge';

export interface OrgSwitcherOrgProps {
  organization: Organization;
  isCurrent?: boolean;
  isSelected?: boolean;
  isSupportAccess?: boolean;
  width?: ButtonProps['width'];
}

export const OrgSwitcherOrg: React.FC<OrgSwitcherOrgProps> = ({
  organization,
  isCurrent,
  isSelected,
  isSupportAccess,
  width = '100%',
}) => {
  const ref = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();
  const color = useColorModeValue('gray.800', 'gray.200');
  const bgColor = useColorModeValue('gray.50', 'gray.800');

  const linkParams = useMemo(
    () => ({
      orgId: organization.id,
    }),
    [organization]
  );

  useEffect(() => {
    if (isSelected) {
      ref.current?.scrollIntoView({ block: 'nearest' });
    }
  }, [isSelected]);

  return (
    <Button
      ref={ref}
      variant="ghost"
      w={width}
      justifyContent="start"
      gap={2}
      borderRadius={0}
      borderLeftWidth={3}
      borderColor={isCurrent ? 'green.500' : 'transparent'}
      color={color}
      p={2}
      _hover={{ bg: bgColor }}
      _selected={{ bg: bgColor }}
      _focusVisible={{ bg: bgColor, outline: 'none' }}
      as={NamedLink}
      name="organization"
      params={linkParams}
      aria-selected={isSelected ? true : undefined}
      data-testid={`org-switcher-org-${organization.organizationName}`}
    >
      {organization.organizationName}
      {organization.type && (
        <OrganizationTypeBadge organization={organization} />
      )}
      {isSupportAccess && (
        <Badge variant="outline">{t('common:supportAccess')}</Badge>
      )}
    </Button>
  );
};
