import React, { useCallback, useMemo } from 'react';

import { ButtonGroup, HStack, Text, useDisclosure } from '@chakra-ui/react';
import { BoxIcon, PlusIcon, XIcon } from 'lucide-react';

import { OperationalDataType } from '@m3ter-com/m3ter-api';
import { IconButton, SearchBar, TagButton } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { UsageQueryBuilderSection } from '@/components/features/usage/query-builder/UsageQueryBuilderSection';
import { UsageQueryBuilderMultiSelectMenu } from '@/components/features/usage/query-builder/UsageQueryBuilderMultiSelectMenu';

import useUsageQueryBuilderDataTypes from './useUsageQueryBuilderDataTypes';

const standardOperationalDataTypes: Array<OperationalDataType> =
  Object.values(OperationalDataType);

export interface UsageQueryBuilderDataTypesProps {
  value: Array<OperationalDataType>;
  onChange: (value: Array<OperationalDataType>) => void;
  operationalDataTypes?: Array<OperationalDataType>;
}

export const UsageQueryBuilderDataTypes: React.FC<
  UsageQueryBuilderDataTypesProps
> = ({
  value,
  onChange,
  operationalDataTypes = standardOperationalDataTypes,
}) => {
  const { t } = useTranslation();
  const {
    filteredSortedDataTypes,
    searchTerm,
    setSearchTerm,
    clearSearchTerm,
  } = useUsageQueryBuilderDataTypes(operationalDataTypes);

  const entityName = t('features:dataExports.operationalDataTypes');
  const lcEntityName = entityName.toLowerCase();

  const { isOpen, onClose, onToggle } = useDisclosure();

  const reset = useCallback(() => {
    onClose();
    clearSearchTerm();
  }, [clearSearchTerm, onClose]);

  const onRemove = useCallback(
    (operationalDataType: OperationalDataType) => {
      onChange(value.filter((type) => type !== operationalDataType));
    },
    [onChange, value]
  );

  const onRemoveAll = useCallback(() => {
    onChange([]);
  }, [onChange]);

  const onAdd = useCallback(
    (types: Array<string>) => {
      onChange([...value, ...types.map((type) => type as OperationalDataType)]);
      reset();
    },
    [onChange, value, reset]
  );

  const options = useMemo(
    () =>
      filteredSortedDataTypes.map((type) => ({
        value: type,
        label: t(`features:dataExports.operationalDataType.${type}`),
        isDisabled: value.includes(type),
      })),
    [filteredSortedDataTypes, t, value]
  );

  return (
    <UsageQueryBuilderSection
      colorScheme="indigo"
      title={entityName}
      icon={BoxIcon}
    >
      <HStack wrap="wrap">
        {value.length === 0 && (
          <Text fontStyle="italic">{t('common:none')}</Text>
        )}
        {value.map((type) => (
          <TagButton
            key={type}
            size="sm"
            colorScheme="indigo"
            closeLabel={t('common:remove')}
            onClose={() => {
              onRemove(type);
            }}
          >
            {t(`features:dataExports.operationalDataType.${type}`)}
          </TagButton>
        ))}
        <ButtonGroup>
          {value.length > 0 && (
            <IconButton
              size="sm"
              colorScheme="indigo"
              variant="subtle"
              aria-label={t('common:removeAll')}
              onClick={onRemoveAll}
              icon={<XIcon size={16} />}
            />
          )}
          <UsageQueryBuilderMultiSelectMenu
            showSelectAll
            isOpen={isOpen}
            onClose={reset}
            trigger={
              <IconButton
                size="sm"
                colorScheme="indigo"
                variant="subtle"
                aria-label={t('forms:buttons.addEntity', {
                  entityName: lcEntityName,
                })}
                icon={<PlusIcon size={16} />}
                onClick={onToggle}
              />
            }
            header={
              <SearchBar
                placeholder={t('common:searchEntity', {
                  entity: lcEntityName,
                })}
                initialValue={searchTerm}
                onSearch={setSearchTerm}
                onClear={clearSearchTerm}
              />
            }
            options={options}
            onAdd={onAdd}
            emptyMessage={t('common:searchEntityNoResults', {
              entity: lcEntityName,
            })}
          />
        </ButtonGroup>
      </HStack>
    </UsageQueryBuilderSection>
  );
};
