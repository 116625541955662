import React from 'react';

import { DataType, IdentityProvider } from '@m3ter-com/m3ter-api';

import { NamedRoute } from '@/types/routes';

import { createCrudRoute } from '@/routes/crud';
import { lazyWithRetry } from '@/routes/lazyWithRetry';

const LazyIdentityProvidersRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "IdentityProvidersRoute" */ '@/components/routes/admin/identity-providers/IdentityProvidersRoute'
  ).then((module) => ({ default: module.IdentityProvidersRoute }))
);

const LazyIdentityProvidersListRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "IdentityProvidersListRoute" */ '@/components/routes/admin/identity-providers/IdentityProvidersListRoute'
  ).then((module) => ({ default: module.IdentityProvidersListRoute }))
);

const LazyIdentityProvidersDetailsRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "IdentityProvidersDetailsRoute" */ '@/components/routes/admin/identity-providers/IdentityProvidersDetailsRoute'
  ).then((module) => ({ default: module.IdentityProvidersDetailsRoute }))
);

const LazyIdentityProvidersCreateRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "IdentityProvidersCreateRoute" */ '@/components/routes/admin/identity-providers/IdentityProvidersCreateRoute'
  ).then((module) => ({ default: module.IdentityProvidersCreateRoute }))
);

const LazyIdentityProvidersEditRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "IdentityProvidersEditRoute" */ '@/components/routes/admin/identity-providers/IdentityProvidersEditRoute'
  ).then((module) => ({ default: module.IdentityProvidersEditRoute }))
);

export default (): NamedRoute =>
  createCrudRoute<IdentityProvider>({
    path: 'identity-providers',
    dataType: DataType.IdentityProvider,
    element: <LazyIdentityProvidersRoute />,
    list: <LazyIdentityProvidersListRoute />,
    details: <LazyIdentityProvidersDetailsRoute />,
    create: <LazyIdentityProvidersCreateRoute />,
    edit: <LazyIdentityProvidersEditRoute />,
  });
