import React, { useMemo } from 'react';

import { ExternalMappingConfig } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormField, FormSelect } from '@m3ter-com/console-core/components';
import { SelectOption } from '@m3ter-com/ui-components';

export interface ExternalMappingEntityTypeFieldProps {
  externalMappingConfig: ExternalMappingConfig;
  name: string;
}

export const ExternalMappingEntityTypeField: React.FC<
  ExternalMappingEntityTypeFieldProps
> = ({ externalMappingConfig, name }) => {
  const { t } = useTranslation();

  const options = useMemo<Array<SelectOption>>(
    () =>
      externalMappingConfig.m3terEntities.map((entityType) => ({
        value: entityType,
        label: t(`features:externalMappings.entityTypes.${entityType}`),
      })),
    [externalMappingConfig?.m3terEntities, t]
  );

  return (
    <FormField
      isRequired
      name={name}
      label={t('forms:labels.entityType')}
      control={FormSelect}
      options={options}
    />
  );
};
