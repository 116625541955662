import React, { useCallback, useMemo } from 'react';

import { useFormContext, useFieldArray, useWatch } from 'react-hook-form';

import { Id, Meter, MeterFieldCategory } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { SelectOption } from '@m3ter-com/ui-components';
import {
  FormAddRowButton,
  FormField,
  FormMultiRowGridWrapper,
  FormMultiRowWrapper,
  FormSelect,
} from '@m3ter-com/console-core/components';

import { FilterField } from './FilterField';

export interface DimensionsFieldProps {
  meters: Array<Meter>;
  name: string;
}

interface DimensionsFieldRowProps {
  fieldNamePrefix: string;
  hideLabels: boolean;
  meters: Array<Meter>;
  onRemove?: () => void;
}

const DimensionsFieldRow: React.FC<DimensionsFieldRowProps> = ({
  fieldNamePrefix,
  meters,
  hideLabels,
  onRemove,
}) => {
  const { t } = useTranslation();

  const meterOptions = useMemo<Array<SelectOption>>(
    () =>
      meters
        ? meters.map((meter) => ({
            value: meter.id,
            label: meter.name,
          }))
        : [],
    [meters]
  );

  // Name options depend on the selected meter.
  const meterId: Id | undefined = useWatch({
    name: `${fieldNamePrefix}.meterId`,
  });

  const selectedMeter = useMemo<Meter | undefined>(
    () => meters.find((meter) => meter.id === meterId),
    [meters, meterId]
  );

  // Create options for all data / derived fields that do not have a category of measure.
  const nameOptions = useMemo<Array<SelectOption>>(
    () =>
      selectedMeter
        ? [...selectedMeter.dataFields, ...selectedMeter.derivedFields]
            .filter((field) => field.category !== MeterFieldCategory.MEASURE)
            .map((field) => ({
              value: field.code,
              label: field.name,
            }))
        : [],
    [selectedMeter]
  );

  return (
    <FormMultiRowGridWrapper
      columnCount={3}
      onRemove={onRemove}
      removeButtonMarginTop={8}
    >
      <FormField
        isRequired
        label={t('common:meter')}
        name={`${fieldNamePrefix}.meterId`}
        hideLabel={hideLabels}
        control={FormSelect}
        options={meterOptions}
      />
      <FormField
        isRequired
        isDisabled={!meterId}
        label={t('features:statements.dimension')}
        name={`${fieldNamePrefix}.name`}
        hideLabel={hideLabels}
        control={FormSelect}
        options={nameOptions ?? []}
      />
      <FormField
        label={t('features:statements.filter')}
        name={`${fieldNamePrefix}.filter`}
        hideLabel={hideLabels}
        control={FilterField}
      />
    </FormMultiRowGridWrapper>
  );
};

export const DimensionsField: React.FC<DimensionsFieldProps> = ({
  name,
  meters,
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name });

  const onAddClick = useCallback(() => {
    append({ filter: ['*'] });
  }, [append]);

  return (
    <React.Fragment>
      <FormMultiRowWrapper
        hasFields={fields.length > 0}
        emptyContentMessage={t('features:statements.noDimensions')}
      >
        {fields.map((field, index) => {
          return (
            <DimensionsFieldRow
              key={field.id}
              meters={meters}
              fieldNamePrefix={`${name}.${index}`}
              hideLabels={index > 0}
              onRemove={() => {
                remove(index);
              }}
            />
          );
        })}
      </FormMultiRowWrapper>
      <FormAddRowButton onAdd={onAddClick} />
    </React.Fragment>
  );
};
