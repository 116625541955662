import React, { useCallback, useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import orderBy from 'lodash/orderBy';
import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Heading,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';

import {
  Aggregation,
  CompoundAggregation,
  Counter,
  CounterPricing,
  DataType,
  Plan,
  PlanTemplate,
  Pricing,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  Alert,
  CardActionsHeader,
  Select,
  SelectOption,
} from '@m3ter-com/ui-components';

import { PricingUsageEntity } from '@/types/data';

import {
  removePricingUsageEntity,
  PlanDetailsPricingDataEntities,
} from '@/store/features/pricing/planDetails';
import { AuditData } from '@/components/common/data/AuditData';
import { PricingGrid } from '@/components/features/pricing/grid/PricingGrid';
import { PlanDetailsPricingGridActions } from '@/components/features/pricing/PlanDetailsPricingGridActions';
import { PlanDetailsContent } from '@/components/features/pricing/PlanDetails/PlanDetailsContent';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import usePlanPricingQueryParams from '@/hooks/features/pricing/usePlanPricingQueryParams';

export interface PlanGroupPlanDetailsAndPricingProps {
  extraPricingAggregations: Array<Aggregation>;
  extraPricingCompoundAggregations: Array<CompoundAggregation>;
  extraPricingItemCounters: Array<Counter>;
  isPlanGroupCustom: boolean;
  isLoading: boolean;
  pricingData: PlanDetailsPricingDataEntities;
}

const emptyArray = new Array<any>();

export const PlanGroupPlanDetailsAndPricing: React.FC<
  PlanGroupPlanDetailsAndPricingProps
> = ({
  extraPricingAggregations,
  extraPricingCompoundAggregations,
  extraPricingItemCounters,
  isPlanGroupCustom,
  isLoading,
  pricingData,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { removeUsageEntitiesFromQueryParams } = usePlanPricingQueryParams();
  const { singular: entityName } = useEntityNamings(DataType.Plan);

  const planOptions = useMemo<Array<SelectOption>>(
    () =>
      orderBy(
        pricingData.plans.map((plan) => ({ label: plan.name, value: plan.id })),
        'label'
      ),
    [pricingData]
  );

  // We use the url as the source of truth to persist the selected plan id so
  // we can re-select the plan when returning to the page from the pricing edit page.
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedPlanId = searchParams.get('selectedPlanId');

  const onSelectPlan = useCallback(
    (newPlanId: string) => {
      setSearchParams({ selectedPlanId: newPlanId }, { replace: true });
    },
    [setSearchParams]
  );

  const selectedPlan = useMemo<Plan | undefined>(
    () =>
      selectedPlanId
        ? pricingData.plans.find((plan) => plan.id === selectedPlanId)
        : undefined,
    [pricingData, selectedPlanId]
  );
  const relevantPlanTemplate = useMemo<PlanTemplate | undefined>(
    () =>
      selectedPlan
        ? pricingData.planTemplates.find(
            (planTemplate) => planTemplate.id === selectedPlan.planTemplateId
          )
        : undefined,
    [pricingData, selectedPlan]
  );
  const selectedPlans = useMemo<Array<Plan>>(
    () => (selectedPlan ? [selectedPlan] : []),
    [selectedPlan]
  );
  const relevantPlanTemplates = useMemo<Array<PlanTemplate>>(
    () => (relevantPlanTemplate ? [relevantPlanTemplate] : []),
    [relevantPlanTemplate]
  );
  const relevantPricings = useMemo<Array<Pricing>>(() => {
    const planPricings = selectedPlan
      ? pricingData.pricings.filter(
          (pricing) => !!pricing.planId && pricing.planId === selectedPlan.id
        )
      : [];
    const planTemplatePricings = relevantPlanTemplate
      ? pricingData.pricings.filter(
          (pricing) =>
            !!pricing.planTemplateId &&
            pricing.planTemplateId === relevantPlanTemplate.id
        )
      : [];

    return [...planPricings, ...planTemplatePricings];
  }, [pricingData, selectedPlan, relevantPlanTemplate]);
  const relevantItemCounterPricings = useMemo<Array<CounterPricing>>(() => {
    const planItemCounterPricings = selectedPlan
      ? pricingData.itemCounterPricings.filter(
          (itemCounterPricing) =>
            !!itemCounterPricing.planId &&
            itemCounterPricing.planId === selectedPlan.id
        )
      : [];
    const planTemplateItemCounterPricings = relevantPlanTemplate
      ? pricingData.itemCounterPricings.filter(
          (itemCounterPricing) =>
            !!itemCounterPricing.planTemplateId &&
            itemCounterPricing.planTemplateId === relevantPlanTemplate.id
        )
      : [];

    return [...planItemCounterPricings, ...planTemplateItemCounterPricings];
  }, [pricingData, selectedPlan, relevantPlanTemplate]);
  const relevantAggregations = useMemo<Array<Aggregation>>(
    () =>
      pricingData.aggregations.filter((aggregation) =>
        relevantPricings.some(
          (pricing) =>
            !!pricing.aggregationId && pricing.aggregationId === aggregation.id
        )
      ),
    [pricingData, relevantPricings]
  );
  const selectedAggregations = useMemo<Array<Aggregation>>(
    () => [...relevantAggregations, ...extraPricingAggregations],
    [extraPricingAggregations, relevantAggregations]
  );
  const relevantCompoundAggregations = useMemo<Array<CompoundAggregation>>(
    () =>
      pricingData.compoundAggregations.filter((compoundAggregation) =>
        relevantPricings.some(
          (pricing) =>
            !!pricing.compoundAggregationId &&
            pricing.compoundAggregationId === compoundAggregation.id
        )
      ),
    [pricingData, relevantPricings]
  );
  const selectedCompoundAggregations = useMemo<Array<CompoundAggregation>>(
    () => [
      ...relevantCompoundAggregations,
      ...extraPricingCompoundAggregations,
    ],
    [extraPricingCompoundAggregations, relevantCompoundAggregations]
  );
  const relevantItemCounters = useMemo<Array<Counter>>(
    () =>
      pricingData.itemCounters.filter((itemCounter) =>
        relevantItemCounterPricings.some(
          (itemCounterPricing) =>
            !!itemCounterPricing.counterId &&
            itemCounterPricing.counterId === itemCounter.id
        )
      ),
    [pricingData, relevantItemCounterPricings]
  );
  const selectedItemCounters = useMemo<Array<Counter>>(
    () => [...relevantItemCounters, ...extraPricingItemCounters],
    [extraPricingItemCounters, relevantItemCounters]
  );

  const onRemovePricingUsageEntity = useCallback(
    (pricingUsageEntity: PricingUsageEntity, isCompound?: boolean) => {
      dispatch(removePricingUsageEntity(pricingUsageEntity.id, isCompound));
      removeUsageEntitiesFromQueryParams(pricingUsageEntity.id, isCompound);
    },
    [dispatch, removeUsageEntitiesFromQueryParams]
  );

  const isCustomPlan = !!selectedPlan?.accountId;

  if (!pricingData.planGroup) {
    return null;
  }

  return pricingData.plans.length === 0 ? (
    <Alert status="info" w="100%">
      {t('features:planGroups.noPlansInPlanGroup')}
    </Alert>
  ) : (
    <React.Fragment>
      <Card>
        <CardActionsHeader
          py={2}
          actions={
            <Box width="30%">
              <Select
                isSearchable
                isDisabled={planOptions.length === 0}
                options={planOptions}
                onChange={onSelectPlan as (planId: string | null) => void} // We can safely cast here since we aren't making the <Select> clearable
                value={selectedPlanId}
                placeholder={t('features:account.selectPlan')}
              />
            </Box>
          }
        >
          <Heading size="md">
            {t('common:entityDetails', { entityName })}
          </Heading>
        </CardActionsHeader>
        <CardBody>
          <VStack spacing={4} alignItems="stretch">
            {!selectedPlan && (
              <Flex justify="center">
                <Text>{t('features:planGroups.selectPlanToViewDetails')}</Text>
              </Flex>
            )}
            {relevantPlanTemplate && selectedPlan && (
              <PlanDetailsContent
                plan={selectedPlan}
                planTemplate={relevantPlanTemplate}
                showIsCustomDetails={false}
              />
            )}
          </VStack>
        </CardBody>
        {selectedPlan && (
          <CardFooter>
            <AuditData data={selectedPlan} variant="horizontal" />
          </CardFooter>
        )}
      </Card>
      <Card>
        <CardHeader>
          <Heading size="md">{t('features:pricing.associatedPricing')}</Heading>
        </CardHeader>
        <CardBody>
          {!selectedPlan && (
            <Flex justify="center">
              <Text>
                {t('features:planGroups.selectPlanToViewPlanPricing')}
              </Text>
            </Flex>
          )}
          {isLoading ? (
            <Spinner data-testid="plan-group-plan-details-and-pricing-loading" />
          ) : (
            relevantPlanTemplate &&
            selectedPlan && (
              <React.Fragment>
                <PlanDetailsPricingGridActions
                  productId={relevantPlanTemplate?.productId}
                  selectedAggregations={selectedAggregations}
                  selectedCompoundAggregations={selectedCompoundAggregations}
                  selectedItemCounters={selectedItemCounters}
                />
                <PricingGrid
                  onRemovePricingUsageEntity={onRemovePricingUsageEntity}
                  linkedPlanTemplates={relevantPlanTemplates}
                  selectedAggregations={selectedAggregations}
                  selectedCompoundAggregations={selectedCompoundAggregations}
                  selectedItemCounters={selectedItemCounters}
                  selectedPlans={selectedPlans}
                  selectedPlanTemplates={emptyArray}
                  pricings={relevantPricings}
                  itemCounterPricings={relevantItemCounterPricings}
                  canEditPlanOrTemplate={false}
                  canEditPricing={isPlanGroupCustom && isCustomPlan}
                  canEditTemplatePricing={false}
                />
              </React.Fragment>
            )
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
