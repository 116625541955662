import React from 'react';

import { OutboundWebhook } from '@m3ter-com/m3ter-api';

import { CrudEdit } from '@/components/common/crud/CrudEdit';
import { OutboundWebhookForm } from '@/components/features/integrations/OutboundWebhookForm/OutboundWebhookForm';

export const OutboundWebhookEditRoute: React.FC = () => (
  <CrudEdit<OutboundWebhook> form={OutboundWebhookForm} />
);
