import React from 'react';

import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import { useTranslation, useFormError } from '@m3ter-com/console-core/hooks';
import { Alert, SelectOption } from '@m3ter-com/ui-components';
import {
  FormAddRowButton,
  FormField,
  FormInput,
  FormMultiRowGridWrapper,
  FormMultiRowWrapper,
  FormSection,
  FormSelect,
  NameCodeFields,
} from '@m3ter-com/console-core/components';

import { isCategoryNumeric } from '@/util/meter';

export interface DataFieldsFieldProps {
  name: string;
  categoryOptions: Array<SelectOption>;
}

const MAX_DATA_FIELDS = 15;

export const DataFieldsField: React.FC<DataFieldsFieldProps> = ({
  name,
  categoryOptions,
}) => {
  const { t } = useTranslation();

  const { control } = useFormContext();
  const values = useWatch({ control, name });
  const { isInvalid, message } = useFormError(name);
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  return (
    <FormSection
      heading={`${t('forms:labels.dataFields')} ${t(
        'forms:labels.maximumWithNumber',
        {
          max: MAX_DATA_FIELDS,
        }
      )}`}
    >
      {isInvalid && message && <Alert status="error">{message}</Alert>}
      <FormMultiRowWrapper hasFields={fields.length > 0}>
        {fields.map((field, index) => {
          const isFirstField = index === 0;
          const namePrefix = `${name}.${index}.`;
          const isUnitFieldDisabled =
            !values[index] || !isCategoryNumeric(values[index].category);
          const canRemove = !isFirstField;

          return (
            <FormMultiRowGridWrapper
              key={field.id}
              columnCount={4}
              onRemove={canRemove ? () => remove(index) : undefined}
            >
              <NameCodeFields
                nameFieldPrefix={namePrefix}
                codeFieldPrefix={namePrefix}
                hideLabels={index > 0}
              />
              <FormField
                label={t('forms:labels.category')}
                name={`${namePrefix}category`}
                control={FormSelect}
                isRequired={false}
                options={categoryOptions}
                hideLabel={index > 0}
              />
              <FormField
                label={t('forms:labels.unit')}
                name={`${namePrefix}unit`}
                control={FormInput}
                isDisabled={isUnitFieldDisabled}
                isRequired={false}
                hideLabel={index > 0}
              />
            </FormMultiRowGridWrapper>
          );
        })}
      </FormMultiRowWrapper>
      <FormAddRowButton
        maxRows={MAX_DATA_FIELDS}
        isDisabled={fields.length >= MAX_DATA_FIELDS}
        onAdd={() => {
          append({});
        }}
      />
    </FormSection>
  );
};
