import React, { ClipboardEvent, useCallback } from 'react';

import { Input } from '../Input/Input';
import { AdvancedNumberInput } from '../AdvancedNumberInput/AdvancedNumberInput';
import { Select } from '../selects/Select/Select';
import { AutocompleteInput } from '../AutocompleteInput/AutocompleteInput';

import { SpreadsheetInputCell } from './types';

export interface SpreadsheetInputCellRendererProps {
  cell?: SpreadsheetInputCell;
  columnIndex: number;
  id: string;
  placeholder?: string;
  rowIndex: number;
  onChange: (rowIndex: number, columnIndex: number, newValue: any) => void;
  onPaste?: (
    focusedRowIndex: number,
    focusedColumnIndex: number,
    event: ClipboardEvent<HTMLInputElement>
  ) => void;
}

export const SpreadsheetInputCellRenderer: React.FC<
  SpreadsheetInputCellRendererProps
> = ({ cell, columnIndex, id, rowIndex, placeholder, onChange, onPaste }) => {
  const handleChange = useCallback(
    (newValueOrEvent: any) => {
      let newValue: any;
      if (newValueOrEvent instanceof Event || newValueOrEvent.nativeEvent) {
        newValue = (newValueOrEvent.target as HTMLInputElement).value;
      } else {
        newValue = newValueOrEvent;
      }
      onChange(rowIndex, columnIndex, newValue);
    },
    [columnIndex, onChange, rowIndex]
  );

  const handlePaste = useCallback(
    (event: ClipboardEvent<HTMLInputElement>) => {
      event.preventDefault();
      if (onPaste) {
        onPaste(rowIndex, columnIndex, event);
      }
    },
    [columnIndex, onPaste, rowIndex]
  );

  switch (cell?.type) {
    case 'string':
      return (
        <Input
          id={id}
          isDisabled={cell.isDisabled}
          onChange={handleChange}
          onPaste={handlePaste}
          placeholder={placeholder}
          value={cell.value ?? ''}
        />
      );
    case 'number':
      return (
        <AdvancedNumberInput
          id={id}
          isDisabled={cell.isDisabled}
          onChange={handleChange}
          onPaste={handlePaste}
          value={cell.value}
        />
      );
    case 'select':
      return (
        <Select
          inputId={id}
          isDisabled={cell.isDisabled}
          isLoading={cell.isLoading}
          onChange={handleChange}
          options={cell.options}
          value={cell.value ?? ''}
        />
      );
    case 'autocomplete':
      return (
        <AutocompleteInput
          id={id}
          isDisabled={cell.isDisabled}
          isLoading={cell.isLoading}
          onChange={handleChange}
          onPaste={handlePaste}
          options={cell.options}
          placeholder={placeholder}
          value={cell.value ?? ''}
        />
      );
    default:
      return null;
  }
};
