import { ComponentMultiStyleConfig, SystemStyleObject } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const cellBaseStyles: SystemStyleObject = {
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'stretch',
  alignItems: 'center',
  width: '100%',
  padding: 1,
  '> *': {
    flex: '1 1 auto',
  },
};

const spreadsheetInput: ComponentMultiStyleConfig = {
  parts: ['header', 'cell'],
  baseStyle: (props) => ({
    header: {
      ...cellBaseStyles,
      fontWeight: 'semibold',
    },
    cell: {
      ...cellBaseStyles,
      backgroundColor: 'white',
      borderWidth: '1px',
      borderColor: mode('border-light', 'bg-subtle-dark')(props),
      borderStyle: 'solid',
    },
  }),
};

export default spreadsheetInput;
