import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { CustomFields } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormSection } from '@m3ter-com/console-core/components';

import { CustomFieldsEditor } from '@/components/forms/CustomFieldsEditor';

export interface FormCustomFieldsEditorProps {
  name?: string;
  title?: string;
  defaultCustomFields?: CustomFields;
  defaultIsOpen?: boolean;
}

export const FormCustomFieldsEditor: React.FC<FormCustomFieldsEditorProps> = ({
  name = 'customFields',
  title,
  defaultCustomFields,
  defaultIsOpen = false,
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <FormSection
      isOptional
      isAccordion
      alignSelf="stretch"
      heading={title ?? t('common:customFields')}
      defaultIsOpen={defaultIsOpen}
    >
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => (
          <CustomFieldsEditor
            defaultCustomFields={defaultCustomFields}
            name={name}
            value={value}
            onChange={onChange}
          />
        )}
      />
    </FormSection>
  );
};
