import React from 'react';

import { DataType, NotificationRule } from '@m3ter-com/m3ter-api';

import { createCrudRoute } from '@/routes/crud';
import { NotificationRulesRoute } from '@/components/routes/events/notification-rules/NotificationRulesRoute';
import { NotificationRulesListRoute } from '@/components/routes/events/notification-rules/NotificationRulesListRoute';
import { NotificationRuleDetailsRoute } from '@/components/routes/events/notification-rules/NotificationRuleDetailsRoute';
import { NotificationRuleCreateRoute } from '@/components/routes/events/notification-rules/NotificationRuleCreateRoute';
import { NotificationRuleEditRoute } from '@/components/routes/events/notification-rules/NotificationRuleEditRoute';

export default () =>
  createCrudRoute<NotificationRule>({
    path: 'notification-rules',
    dataType: DataType.NotificationRule,
    element: <NotificationRulesRoute />,
    list: <NotificationRulesListRoute />,
    details: <NotificationRuleDetailsRoute />,
    create: <NotificationRuleCreateRoute />,
    edit: <NotificationRuleEditRoute />,
  });
