import { useCallback, KeyboardEvent } from 'react';

import { Input } from '@chakra-ui/react';

export interface ManualValueInputProps {
  placeholder: string;
  onSubmit: (value: string) => void;
}

export const ManualValueInput: React.FC<ManualValueInputProps> = ({
  placeholder,
  onSubmit,
}) => {
  const onKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        // Prevent submitting the `form` that wraps the query builder fields.
        event.preventDefault();

        if (event.currentTarget.value !== '') {
          onSubmit(event.currentTarget.value);
        }
      }
    },
    [onSubmit]
  );

  return (
    <Input
      size="sm"
      placeholder={placeholder}
      aria-label={placeholder}
      onKeyDown={onKeyDown}
    />
  );
};
