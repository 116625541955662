import React, { useCallback, useMemo } from 'react';

import { useFormContext, useFieldArray, useWatch } from 'react-hook-form';

import {
  AggregationType,
  Id,
  Meter,
  MeterFieldCategory,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { SelectOption } from '@m3ter-com/ui-components';
import {
  FormAddRowButton,
  FormField,
  FormMultiRowGridWrapper,
  FormMultiRowWrapper,
  FormMultiSelect,
  FormSelect,
} from '@m3ter-com/console-core/components';

export interface MeasuresFieldProps {
  meters: Array<Meter>;
  name: string;
}

interface MeasuresFieldRowProps {
  fieldNamePrefix: string;
  hideLabels: boolean;
  meters: Array<Meter>;
  onRemove?: () => void;
}

const MeasuresFieldRow: React.FC<MeasuresFieldRowProps> = ({
  fieldNamePrefix,
  meters,
  hideLabels,
  onRemove,
}) => {
  const { t } = useTranslation();

  const meterOptions = useMemo<Array<SelectOption>>(
    () =>
      meters
        ? meters.map((meter) => ({
            value: meter.id,
            label: meter.name,
          }))
        : [],
    [meters]
  );

  // Name options depend on the selected meter.
  const meterId: Id | undefined = useWatch({
    name: `${fieldNamePrefix}.meterId`,
  });

  const selectedMeter = useMemo<Meter | undefined>(
    () => meters.find((meter) => meter.id === meterId),
    [meters, meterId]
  );

  // Create options for all data / derived fields that have a category of measure.
  const nameOptions = useMemo<Array<SelectOption>>(
    () =>
      selectedMeter
        ? [...selectedMeter.dataFields, ...selectedMeter.derivedFields]
            .filter((field) => field.category === MeterFieldCategory.MEASURE)
            .map((field) => ({
              value: field.code,
              label: field.name,
            }))
        : [],
    [selectedMeter]
  );

  const aggregationFunctionOptions = useMemo<Array<SelectOption>>(
    () =>
      Object.values(AggregationType).map((aggValue) => ({
        value: aggValue,
        label: t(`features:usage.aggregationType.${aggValue}`),
      })),
    [t]
  );

  return (
    <FormMultiRowGridWrapper columnCount={3} onRemove={onRemove}>
      <FormField
        isRequired
        label={t('common:meter')}
        name={`${fieldNamePrefix}.meterId`}
        hideLabel={hideLabels}
        control={FormSelect}
        options={meterOptions}
      />
      <FormField
        isRequired
        isDisabled={!meterId}
        label={t('features:statements.measure')}
        name={`${fieldNamePrefix}.name`}
        hideLabel={hideLabels}
        control={FormSelect}
        options={nameOptions ?? []}
      />
      <FormField
        label={t('features:statements.aggregationFunctions')}
        name={`${fieldNamePrefix}.aggregations`}
        hideLabel={hideLabels}
        control={FormMultiSelect}
        options={aggregationFunctionOptions}
      />
    </FormMultiRowGridWrapper>
  );
};

export const MeasuresField: React.FC<MeasuresFieldProps> = ({
  name,
  meters,
}) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name });

  const onAddClick = useCallback(() => {
    append({});
  }, [append]);

  return (
    <React.Fragment>
      <FormMultiRowWrapper hasFields={fields.length > 0}>
        {fields.map((field, index) => {
          return (
            <MeasuresFieldRow
              key={field.id}
              meters={meters}
              fieldNamePrefix={`${name}.${index}`}
              hideLabels={index > 0}
              onRemove={
                index > 0
                  ? () => {
                      remove(index);
                    }
                  : undefined
              }
            />
          );
        })}
      </FormMultiRowWrapper>
      <FormAddRowButton onAdd={onAddClick} />
    </React.Fragment>
  );
};
