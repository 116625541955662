import React, { PropsWithChildren } from 'react';

import {
  Box,
  Grid,
  IconButtonProps,
  StackDivider,
  StackProps,
  StyleProps,
  VStack,
} from '@chakra-ui/react';
import { XIcon } from 'lucide-react';

import { IconButton } from '@m3ter-com/ui-components';

import { useTranslation } from '../../../hooks';

interface FormMultiRowWrapperProps extends StackProps {
  hasFields: boolean;
  emptyContentMessage?: string;
}

interface FormMultiRowWGridrapperProps extends StyleProps {
  columnCount: number;
  onRemove?: () => void;
  removeButtonGridRow?: IconButtonProps['gridRow'];
  removeButtonMarginTop?: IconButtonProps['marginTop'];
}

/**
 * `FormMultiRowGridWrapper` is a React functional component that renders a grid layout
 * with a specified number of columns and an optional remove button. It is intended to be used
 * for wrapping `FormField` components in a multi-row form.
 *
 * Note: `FormMultiRowGridWrapper` expects `FormField` components as children. These components
 * utilize CSS subgrid to align labels, help text, alerts, inputs, and error messages in that order.
 * When `onRemove` is provided, the remove button will be rendered in the last column and aligned
 * with the input in the subgrid row (row 4 of the of the `FormField` form control subgrid).
 */
export const FormMultiRowGridWrapper: React.FC<
  PropsWithChildren<FormMultiRowWGridrapperProps>
> = ({
  columnCount,
  children,
  onRemove,
  removeButtonGridRow,
  removeButtonMarginTop,
  ...styles
}) => {
  const { t } = useTranslation();

  return (
    <Grid
      columnGap={4}
      gridTemplateColumns={`repeat(${columnCount},  1fr) 24px`}
      role="listitem"
      width="100%"
      alignItems="flex-end"
      {...styles}
    >
      {children}
      {onRemove && (
        <Box
          display="grid"
          gridRow={removeButtonGridRow ?? 4} // aligned with input (default FormField subgrid row 4)
          gridColumn={columnCount + 1} // last column
          gridTemplateRows="subgrid"
        >
          <IconButton
            aria-label={t('common:remove')}
            size="xs"
            mt={removeButtonMarginTop ?? 0}
            alignSelf="center"
            icon={<XIcon size={16} />}
            onClick={onRemove}
          />
        </Box>
      )}
    </Grid>
  );
};

export const FormMultiRowWrapper: React.FC<
  PropsWithChildren<FormMultiRowWrapperProps>
> = ({ hasFields, emptyContentMessage, children, ...props }) => {
  if (hasFields) {
    return (
      <VStack
        w="100%"
        role="list"
        alignItems="stretch"
        spacing={4}
        divider={<StackDivider />}
        {...props}
      >
        {children}
      </VStack>
    );
  }

  return emptyContentMessage ? <p>{emptyContentMessage}</p> : null;
};
