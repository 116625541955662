import { useMutation, useQuery } from '@tanstack/react-query';

import {
  getNotificationRuleLinkedWebhooks,
  Id,
  linkWebhooksToNotificationRule,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import useOrg from '@/hooks/data/crud/useOrg';
import useNotification from '@/hooks/util/useNotification';

const useNotificationRuleLinkedWebhooks = (notificationRuleId: Id) => {
  const { t } = useTranslation();

  const { currentOrgId: organizationId } = useOrg();

  const {
    data: linkedWebhooks = [],
    error: linkedWebhooksError,
    isFetching: isFetchingLinkedWebhooks,
    refetch: refetchLinkedWebhooks,
  } = useQuery({
    queryFn: () =>
      getNotificationRuleLinkedWebhooks(organizationId, notificationRuleId),
    queryKey: [
      'notification-rule-linked-webhooks',
      organizationId,
      notificationRuleId,
    ],
  });

  const { toast } = useNotification();
  const { mutate: onLinkWebhooks, isPending: isLinkingWebhooks } = useMutation({
    mutationFn: (webhookIds: Array<Id>) =>
      linkWebhooksToNotificationRule(
        organizationId,
        notificationRuleId,
        webhookIds
      ),
    onSuccess: (notificationRuleWebhookLinks, webhookIds) => {
      if (notificationRuleWebhookLinks.length === webhookIds.length) {
        toast({
          description: t('notifications:notificationRuleWebhooksLinkedSuccess'),
          status: 'success',
          duration: 5000,
        });
      } else {
        toast({
          description: t(
            'notifications:notificationRuleWebhooksLinkedFailure',
            {
              failed: webhookIds.length - notificationRuleWebhookLinks.length,
              selected: webhookIds.length,
            }
          ),
          status: 'error',
        });
      }
      refetchLinkedWebhooks();
    },
  });

  return {
    error: linkedWebhooksError,
    isLinkingWebhooks,
    isLoading: isFetchingLinkedWebhooks,
    linkedWebhooks,
    onLinkWebhooks,
    refreshLinkedWebhooks: () => refetchLinkedWebhooks(),
  };
};

export default useNotificationRuleLinkedWebhooks;
