import React, { useCallback, useEffect, useMemo, useState } from 'react';

import isEqual from 'lodash/isEqual';
import { FormControl, FormErrorMessage, VStack } from '@chakra-ui/react';

import { CustomFields } from '@m3ter-com/m3ter-api';
import { useFormError, useTranslation } from '@m3ter-com/console-core/hooks';
import {
  SelectOption,
  SpreadsheetInput,
  SpreadsheetInputRow,
} from '@m3ter-com/ui-components';

export interface CustomFieldsEditorProps {
  defaultCustomFields?: CustomFields;
  name: string;
  value?: CustomFields;
  onChange: (newValue: CustomFields) => void;
}

type CustomFieldValueType = 'string' | 'number';

const emptyCustomFields: CustomFields = {};

export const CustomFieldsEditor: React.FC<CustomFieldsEditorProps> = ({
  defaultCustomFields = emptyCustomFields,
  name,
  value = emptyCustomFields,
  onChange,
}) => {
  const { t } = useTranslation();
  const { isInvalid, message } = useFormError(name);

  const spreadsheetColumnLabels = useMemo<Array<string>>(
    () => [
      t('forms:labels.name'),
      t('forms:labels.type'),
      t('forms:labels.value'),
    ],
    [t]
  );

  const defaultCustomFieldKeys = useMemo<Array<string>>(
    () => Object.keys(defaultCustomFields),
    [defaultCustomFields]
  );

  const spreadsheetRowTypeOptions = useMemo<
    Array<SelectOption<CustomFieldValueType>>
  >(
    () => [
      { value: 'string', label: t('features:customFields.string') },
      { value: 'number', label: t('features:customFields.number') },
    ],
    [t]
  );

  const getEmptySpreadsheetRow = useCallback<() => SpreadsheetInputRow>(
    () => [
      {
        type: 'autocomplete',
        options: defaultCustomFieldKeys,
        value: '',
      },
      {
        type: 'select',
        isDisabled: false,
        options: spreadsheetRowTypeOptions,
        value: 'string',
      },
      {
        type: 'string',
        value: '',
      },
    ],
    [defaultCustomFieldKeys, spreadsheetRowTypeOptions]
  );

  // Usually here, we would convert the incoming customFields into spreadsheet rows for the input with a useMemo
  // and then call onChange directly whenever the spreadsheet is updated.
  // We can't do that for custom fields though because it's possible to have two custom fields occupy the same name / key
  // when typing which would be impossible to track if using the customFields prop as the source of truth.
  // Instead, we keep an internal state of the spreadsheet rows and call the onCustomFieldsChange when we need to.
  const [spreadsheetRows, setSpreadsheetRows] = useState<
    Array<SpreadsheetInputRow>
  >(() => {
    return Object.entries(value).map(([rowKey, rowValue]) => {
      const row: SpreadsheetInputRow = [];
      // First cell is for the key/name of the custom field
      row.push({
        type: 'autocomplete',
        options: defaultCustomFieldKeys,
        value: rowKey,
      });

      // Second cell is to choose whether the custom field value will be a number or a string
      const isNumberRow = typeof rowValue === 'number';
      row.push({
        type: 'select',
        isDisabled: defaultCustomFieldKeys.includes(rowKey),
        options: spreadsheetRowTypeOptions,
        value: isNumberRow ? 'number' : 'string',
      });

      // Third cell is to enter the custom field value
      if (isNumberRow) {
        row.push({
          type: 'number',
          value: rowValue,
        });
      } else {
        row.push({
          type: 'string',
          value: rowValue,
        });
      }
      return row;
    });
  });

  // When the spreadsheet rows are updated, we can re-build the custom fields from the rows and call onChange if needed
  useEffect(() => {
    const newCustomFields = spreadsheetRows.reduce<CustomFields>((acc, row) => {
      const [keyCell, _, valueCell] = row;
      if (
        typeof keyCell?.value === 'string' &&
        valueCell?.value !== undefined
      ) {
        acc[keyCell.value as string] = valueCell.value;
      }
      return acc;
    }, {});

    if (!isEqual(newCustomFields, value)) {
      onChange(newCustomFields);
    }
  }, [value, onChange, spreadsheetRows]);

  const handleSpreadsheetRowsChange = useCallback(
    (newRows: Array<SpreadsheetInputRow>) => {
      // When a change is made in the spreadsheet, we have a few things to check:
      // 1. Has data been pasted in which has led to incomplete rows
      // 2. Has the key cell been updated to match one of the default keys? If so, we need to update the type selection to
      // match the type of the default field and disable type selection
      // 3. If the type option for a row has changed, we need to update the value cell to match the new type
      const checkedRows = newRows.map((row) => {
        const keyCell = row[0];
        let typeCell = row[1];
        const valueCell = row[2];
        // 1.
        if (!keyCell || !typeCell || !valueCell) {
          return getEmptySpreadsheetRow();
        }
        // 2.
        if (
          typeof keyCell.value === 'string' &&
          defaultCustomFieldKeys.includes(keyCell.value)
        ) {
          const defaultFieldType = typeof defaultCustomFields[
            keyCell.value
          ] as CustomFieldValueType;
          typeCell = {
            isDisabled: true,
            options: spreadsheetRowTypeOptions,
            type: 'select',
            value: defaultFieldType,
          };
        } else {
          typeCell.isDisabled = false;
        }
        // 3.
        valueCell.type = typeCell.value as CustomFieldValueType;
        if (
          valueCell.type === 'number' &&
          typeof valueCell.value !== 'number'
        ) {
          const checkedCellValue = parseFloat(valueCell.value || '');
          valueCell.value = Number.isNaN(checkedCellValue)
            ? 0
            : checkedCellValue;
        }
        if (
          valueCell.type === 'string' &&
          typeof valueCell.value !== 'string'
        ) {
          valueCell.value = `${valueCell.value}`;
        }

        return [keyCell, typeCell, valueCell];
      });
      setSpreadsheetRows(checkedRows);
    },
    [
      defaultCustomFieldKeys,
      defaultCustomFields,
      getEmptySpreadsheetRow,
      spreadsheetRowTypeOptions,
    ]
  );

  const handleRemoveSpreadsheetRow = useCallback((rowIndex: number) => {
    setSpreadsheetRows((currentRows) =>
      currentRows.filter((_, rI) => rI !== rowIndex)
    );
  }, []);

  const handleAddSpreadsheetRow = useCallback(() => {
    setSpreadsheetRows((currentRows) => [
      ...currentRows,
      getEmptySpreadsheetRow(),
    ]);
  }, [getEmptySpreadsheetRow]);

  return (
    <VStack spacing={0} width="100%">
      <SpreadsheetInput
        columnLabels={spreadsheetColumnLabels}
        id={name}
        onAddRow={handleAddSpreadsheetRow}
        onRemoveRow={handleRemoveSpreadsheetRow}
        onRowsChange={handleSpreadsheetRowsChange}
        rows={spreadsheetRows}
        addButtonLabel={t('common:add')}
      />
      <FormControl isInvalid={!!isInvalid}>
        {!!isInvalid && <FormErrorMessage>{message}</FormErrorMessage>}
      </FormControl>
    </VStack>
  );
};
