import React from 'react';

import {
  BoxesIcon,
  BuildingIcon,
  ShieldCheckIcon,
  Trash2Icon,
  UsersIcon,
} from 'lucide-react';

import { DataType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Navigation } from '@m3ter-com/ui-components';

import { NamedNavLink } from '@/components/common/navigation/NamedNavLink';
import { CrudRouteType, getCrudRouteName } from '@/routes/crud';

export const AdminNavigation: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Navigation>
      <NamedNavLink
        name={getCrudRouteName(DataType.OrganizationAdmin, CrudRouteType.List)}
        icon={<BoxesIcon />}
      >
        {t('common:organizations')}
      </NamedNavLink>
      <NamedNavLink
        name={getCrudRouteName(DataType.Customer, CrudRouteType.List)}
        icon={<BuildingIcon />}
      >
        {t('common:customers')}
      </NamedNavLink>
      <NamedNavLink
        name={getCrudRouteName(DataType.UserAdmin, CrudRouteType.List)}
        icon={<UsersIcon />}
      >
        {t('common:users')}
      </NamedNavLink>
      <NamedNavLink
        name={getCrudRouteName(DataType.IdentityProvider, CrudRouteType.List)}
        icon={<ShieldCheckIcon />}
      >
        {t('common:identityProviders')}
      </NamedNavLink>
      <NamedNavLink
        name={getCrudRouteName(
          DataType.MeasurmentsDeletion,
          CrudRouteType.List
        )}
        icon={<Trash2Icon />}
      >
        {t('common:measurementsDeletions')}
      </NamedNavLink>
    </Navigation>
  );
};
