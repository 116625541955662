import React from 'react';

import {
  Badge,
  Flex,
  Image,
  StyleProps,
  Text,
  useColorMode,
} from '@chakra-ui/react';

export interface ExternalSystemLogoProps extends StyleProps {
  system: string;
}

export const ExternalSystemLogo: React.FC<ExternalSystemLogoProps> = ({
  system,
  ...styleProps
}) => {
  let imgPath: string | undefined;
  const { colorMode } = useColorMode();
  switch (system.toLowerCase()) {
    case 'aws':
      imgPath = `/logos/aws-${colorMode}.svg`;
      break;
    case 'chargebee':
      imgPath = `/logos/chargebee-${colorMode}.svg`;
      break;
    case 'netsuite':
      imgPath = `/logos/netsuite-${colorMode}.svg`;
      break;
    case 'paddle':
    case 'paddlesandbox':
      imgPath = `/logos/paddle-${colorMode}.svg`;
      break;
    case 'salesforce':
      imgPath = `/logos/salesforce-partner-${colorMode}.svg`;
      break;
    case 'stripe':
    case 'stripetest':
      imgPath = `/logos/stripe-light.svg`;
      break;
    case 'quickbooks':
    case 'quickbookssandbox':
      imgPath = `/logos/quickbooks-${colorMode}.svg`;
      break;
    case 'xero':
      imgPath = `/logos/xero-light.svg`;
      break;
    default:
    // No-op
  }

  let badgeText: string | undefined;
  switch (system.toLowerCase()) {
    case 'stripetest':
      badgeText = 'Test mode';
      break;
    case 'paddlesandbox':
    case 'quickbookssandbox':
      badgeText = 'Sandbox mode';
      break;
    default:
    // No-op
  }

  return (
    <Flex
      alignItems="center"
      flexFlow="row nowrap"
      gap={2}
      justifyContent="flex-start"
      {...styleProps}
    >
      {imgPath ? (
        <Image
          alt={system}
          height="100%"
          maxWidth="100%"
          src={imgPath}
          // Applying a tiny rotation that you can't really see, for some reason,
          // gives sharper SVGs in chrome. Apparently a known bug with chromium's
          // SVG antialising ¯\_(ツ)_/¯
          transform="rotate(0.25deg)"
        />
      ) : (
        <Text>{system}</Text>
      )}
      {!!badgeText && (
        <Badge colorScheme="orange" fontSize="2xs" alignSelf="center">
          {badgeText}
        </Badge>
      )}
    </Flex>
  );
};
