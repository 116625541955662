import React from 'react';

import { Navigate } from 'react-router-dom';

import { NamedRoute } from '@/types/routes';

import { RouteWrapper } from '@/components/routes/helpers/RouteWrapper';
import { IntegrationsNavigation } from '@/components/common/navigation/sections/IntegrationsNavigation/IntegrationsNavigation';

import setupConfigsRoute from './configs';
import setupCredentialsRoute from './credentials';
import setupOutboundWebhooksRoute from './outbound-webhooks';
import setupEventsRoute from './events';
import setupExternalMappingsRoute from './external-mappings';
import setupIntegrationRunsRoute from './runs';

export default (): NamedRoute => ({
  name: 'integrations',
  path: 'integrations',
  element: <RouteWrapper navigation={<IntegrationsNavigation />} />,
  children: [
    { index: true, element: <Navigate replace to="configurations" /> },
    setupConfigsRoute(),
    setupCredentialsRoute(),
    setupOutboundWebhooksRoute(),
    setupEventsRoute(),
    setupExternalMappingsRoute(),
    setupIntegrationRunsRoute(),
  ],
});
