import React, { useMemo } from 'react';

import {
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Heading,
} from '@chakra-ui/react';

import { DataType, Id, NotificationEvent } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { OtherListIds } from '@/types/lists';

import useDateFormatter from '@/hooks/util/useDateFormatter';
import {
  ColumnDefinition,
  CrudList,
  CrudListFooter,
  CrudListRefreshButton,
  CrudListTable,
} from '@/components/common/crud/CrudList';

interface NotificationRuleEventsProps {
  notificationRuleId: Id;
}

export const NotificationRuleEvents: React.FC<NotificationRuleEventsProps> = ({
  notificationRuleId,
}) => {
  const { t } = useTranslation();
  const { toLongDateTime } = useDateFormatter();

  const columns = useMemo<Array<ColumnDefinition<NotificationEvent>>>(
    () => [
      {
        id: 'eventName',
        header: t('forms:labels.event'),
        accessor: 'eventName',
      },
      {
        id: 'eventTime',
        header: t('forms:labels.date'),
        accessor: (item) => toLongDateTime(item.eventTime),
      },
    ],
    [toLongDateTime, t]
  );

  const params = useMemo(
    () => ({ notificationId: notificationRuleId }),
    [notificationRuleId]
  );

  return (
    <CrudList<NotificationEvent>
      listId={OtherListIds.NotificationRuleEvents}
      dataType={DataType.M3terEvent}
      params={params}
    >
      <Card>
        <CardHeader
          alignItems="center"
          display="flex"
          flexFlow="row nowrap"
          justifyContent="space-between"
        >
          <Heading size="md">
            {t('features:notifications.latestEvents')}
          </Heading>
          <ButtonGroup size="sm">
            <CrudListRefreshButton />
          </ButtonGroup>
        </CardHeader>
        <CardBody
          alignItems="stretch"
          display="flex"
          flexFlow="column nowrap"
          justifyContent="flex-start"
          width="100%"
        >
          <CrudListTable columns={columns} />
          <CrudListFooter />
        </CardBody>
      </Card>
    </CrudList>
  );
};
