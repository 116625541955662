import React, { ClipboardEvent, ReactNode, useCallback } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import {
  Box,
  ButtonGroup,
  Grid,
  IconButton,
  Text,
  useFormControl,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { PlusIcon, XIcon } from 'lucide-react';

import { Button } from '../../controls/Button/Button';

import { SpreadsheetInputCell, SpreadsheetInputRow } from './types';
import { SpreadsheetInputCellRenderer } from './SpreadsheetInputCellRenderer';

export interface SpreadsheetInputProps {
  actions?: ReactNode;
  columnLabels: Array<string>;
  enablePasting?: boolean;
  id?: string;
  placeholder?: string;
  rows: Array<SpreadsheetInputRow>;
  addButtonLabel?: string;
  onAddRow?: () => void;
  onRemoveRow?: (rowIndex: number) => void;
  onRowsChange: (newRows: Array<SpreadsheetInputRow>) => void;
}

const getCellBorderRadius = (
  rowIndex: number,
  columnIndex: number,
  rowsCount: number,
  columnsCount: number
): string | undefined => {
  if (rowIndex === 0) {
    if (columnIndex === 0) {
      return rowsCount === 1 ? '6px 0 0 6px' : '6px 0 0 0';
    }

    if (columnIndex === columnsCount - 1) {
      return rowsCount === 1 ? '0 6px 6px 0' : '0 6px 0 0';
    }
  }

  if (rowIndex === rowsCount - 1) {
    if (columnIndex === 0) {
      return '0 0 0 6px';
    }

    if (columnIndex === columnsCount - 1) {
      return '0 0 6px 0';
    }
  }

  return undefined;
};

export const SpreadsheetInput: React.FC<SpreadsheetInputProps> = ({
  actions,
  columnLabels,
  enablePasting = true,
  id: propId,
  placeholder,
  rows,
  onAddRow,
  onRemoveRow,
  onRowsChange,
  addButtonLabel = 'Add row',
}) => {
  const onCellChange = useCallback(
    (rowIndex: number, columnIndex: number, newValue: any) => {
      const updatedRows = rows.map((row, rI) => {
        // Clone each cell in the row so consumers don't recieve references to the original cell objects.
        // Update the cell value if we're on the cell that was changed.
        const updatedRow = row.map((cell, cI) => ({
          ...cell,
          value: rI === rowIndex && cI === columnIndex ? newValue : cell.value,
        }));
        return updatedRow;
      });
      onRowsChange(updatedRows);
    },
    [onRowsChange, rows]
  );

  const onCellPaste = useCallback(
    (
      focusedRowIndex: number,
      focusedColumnIndex: number,
      event: ClipboardEvent<HTMLInputElement>
    ) => {
      const pastedData = event.clipboardData.getData('text');
      if (!pastedData) {
        // No point working on an empty string
        return;
      }

      // Data copied from popular spreadsheet applications is a string in the following format:
      // Cells in rows seperated, where each cell is seperated by a tab
      // Rows are then seperated by new lines
      // Split by those here to get a matrix similar to our rows prop
      const pastedRows = pastedData.split('\n').map((row) => row.split('\t'));

      // Clone the existing rows as a starting point.
      const newRows = rows.map((row) => row.map((cell) => ({ ...cell })));
      // Go over the cells of pasted data and update our new rows with the pasted values
      pastedRows.forEach((pastedRow, pastedRowIndex) => {
        pastedRow.forEach((pastedCellValue, pastedColumnIndex) => {
          // The user can paste into any cell so we need to shift all indexes in the pasted data
          const rowIndex = pastedRowIndex + focusedRowIndex;
          const columnIndex = pastedColumnIndex + focusedColumnIndex;
          // If someone is copying data from a spreadsheet that has more columns than we support, ignore
          // these extra columns
          if (columnIndex >= columnLabels.length) {
            return;
          }

          // Make sure the row we want to update exists.
          // We could be dealing with pasted data that has more rows than the previous state.
          // Use the first row as a template for new rows since we can't know what types etc cells of the row need to be.
          if (!newRows[rowIndex]) {
            newRows[rowIndex] = cloneDeep(rows[0]).map((cell) => ({
              ...cell,
              value: undefined,
            }));
          }

          // For each pasted cell value, we make sure it fits with the type of data we expect for the cell
          // it is being pasted into.
          // If it does, we'll put it in. If not, we won't.

          const pasteTargetCell = newRows[rowIndex][columnIndex];
          let newCellValue;
          switch (pasteTargetCell.type) {
            case 'number': {
              const parsedPastedCellValue = parseFloat(pastedCellValue);
              if (!Number.isNaN(parsedPastedCellValue)) {
                newCellValue = parsedPastedCellValue;
              }
              break;
            }
            case 'select': {
              newCellValue = pasteTargetCell.options.some(
                (option) => option.value === pastedCellValue
              )
                ? pastedCellValue
                : '';
              break;
            }
            default: {
              newCellValue = pastedCellValue;
            }
          }

          // Update the cell
          newRows[rowIndex][columnIndex].value = newCellValue;
        });
      });

      onRowsChange(newRows);
    },
    [columnLabels, onRowsChange, rows]
  );

  const styles = useMultiStyleConfig('SpreadsheetInput', {});
  const { id: formControlId } = useFormControl({});
  const id = propId || formControlId;

  return (
    <Grid
      gridTemplateColumns={`repeat(${columnLabels.length}, 2fr)${
        onRemoveRow ? ' auto' : ''
      }`}
      id={id}
      width="100%"
    >
      {columnLabels.map((columnLabel) => (
        <Box key={columnLabel} sx={styles.header}>
          <Text>{columnLabel}</Text>
        </Box>
      ))}
      {!!onRemoveRow && <Box sx={styles.header} />}
      {rows.map((row, rowIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={rowIndex}>
          {columnLabels.map((_, columnIndex) => {
            const cell = row?.[columnIndex] as SpreadsheetInputCell | undefined;
            return (
              <Box
                // eslint-disable-next-line react/no-array-index-key
                key={`${rowIndex}-${columnIndex}`}
                borderRadius={getCellBorderRadius(
                  rowIndex,
                  columnIndex,
                  rows.length,
                  columnLabels.length
                )}
                sx={styles.cell}
              >
                <SpreadsheetInputCellRenderer
                  cell={cell}
                  columnIndex={columnIndex}
                  id={`${id}-r${rowIndex}-c${columnIndex}`}
                  placeholder={placeholder}
                  rowIndex={rowIndex}
                  onChange={onCellChange}
                  onPaste={enablePasting ? onCellPaste : undefined}
                />
              </Box>
            );
          })}
          {!!onRemoveRow && (
            <Box
              alignItems="center"
              display="flex"
              flexFlow="row nowrap"
              justifyContent="center"
              px={2}
            >
              <IconButton
                aria-label={`Remove row ${rowIndex + 1}`}
                icon={<XIcon size={16} />}
                size="xs"
                onClick={() => {
                  onRemoveRow(rowIndex);
                }}
              />
            </Box>
          )}
        </React.Fragment>
      ))}
      {!!(onAddRow || actions) && (
        <ButtonGroup gap={2} px={1} py={2} size="xs">
          {!!onAddRow && (
            <Button
              size="xs"
              leftIcon={<PlusIcon size={16} />}
              onClick={onAddRow}
              alignSelf="start"
            >
              {addButtonLabel}
            </Button>
          )}
          {actions}
        </ButtonGroup>
      )}
    </Grid>
  );
};
