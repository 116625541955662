import { combineReducers } from 'redux';

import accountsReducer from './accounts/accounts';
import analyticsReducer from './analytics/analytics';
import billingReducer from './billing/billing';
import flagsReducer from './flags/flags';
import pricingReducer from './pricing/pricing';

const featuresReducer = combineReducers({
  accounts: accountsReducer,
  analytics: analyticsReducer,
  billing: billingReducer,
  flags: flagsReducer,
  pricing: pricingReducer,
});

export type FeaturesState = ReturnType<typeof featuresReducer>;

export default featuresReducer;
