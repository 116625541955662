import React from 'react';

import { Navigate } from 'react-router-dom';

import { NamedRoute } from '@/types/routes';

import { AdminRoute } from '@/components/routes/admin/AdminRoute';

import setupCustomerAdminRoutes from './customers';
import setupIdentityProvidersRoutes from './identity-providers';
import setupOrganizationAdminRoutes from './organizations';
import setupMeasurementsDeletionAdminRoutes from './measurements-deletions';
import setupUserAdminRoutes from './users';

export default (): NamedRoute => ({
  name: 'admin',
  path: 'admin',
  element: <AdminRoute />,
  children: [
    {
      index: true,
      element: <Navigate replace to="organizations" />,
    },
    setupCustomerAdminRoutes(),
    setupIdentityProvidersRoutes(),
    setupOrganizationAdminRoutes(),
    setupMeasurementsDeletionAdminRoutes(),
    setupUserAdminRoutes(),
  ],
});
