import React, { ReactNode } from 'react';

import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  VisuallyHidden,
  GridItem,
  Spinner,
  FormLabelProps,
} from '@chakra-ui/react';

import { FCWithControl } from '../../../types';
import { useFormError } from '../../../hooks';
import { useFieldsetContext } from '../FormFieldset/FormFieldset';

export interface FormFieldProps {
  name: string;
  label: string;
  hideLabel?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isRequired?: boolean;
  isReadOnly?: boolean;
  showField?: boolean;
  helpText?: ReactNode;
  alert?: ReactNode;
  labelMarginTop?: FormLabelProps['marginTop'];
}

export const FormField: FCWithControl<FormFieldProps> = (props) => {
  const fieldset = useFieldsetContext();

  const {
    name,
    label,
    isDisabled = fieldset?.isDisabled ?? false,
    isLoading = false,
    isRequired = false,
    isReadOnly = false,
    hideLabel = false,
    showField = true,
    helpText,
    alert,
    type,
    labelMarginTop,
    control: Control,
    ...controlProps
  } = props;

  const { isInvalid, message } = useFormError(name);

  const formLabel = <FormLabel mt={labelMarginTop}>{label}</FormLabel>;

  return (
    <FormControl
      id={name}
      isInvalid={!!isInvalid}
      isDisabled={isDisabled}
      isRequired={isRequired}
      isReadOnly={isReadOnly}
      display={type === 'hidden' ? 'none' : 'grid'}
      gap={0}
      gridRow="span 5"
      gridTemplateRows="subgrid"
      cursor={isReadOnly ? 'no-drop' : 'initial'}
    >
      <GridItem gridRow={1}>
        {hideLabel ? (
          <VisuallyHidden mt={labelMarginTop}>{formLabel}</VisuallyHidden>
        ) : (
          formLabel
        )}
      </GridItem>
      {isLoading ? (
        <GridItem height="40px" gridRow={4} mt={0} alignContent="center">
          <Spinner />
        </GridItem>
      ) : (
        <React.Fragment>
          <GridItem gridRow={2}>
            {!hideLabel && helpText && (
              <FormHelperText mt={0} mb={2}>
                {helpText}
              </FormHelperText>
            )}
          </GridItem>
          <GridItem gridRow={3} mt={0} mb={alert ? 2 : 0}>
            {!hideLabel && alert}
          </GridItem>
          <GridItem gridRow={4} alignSelf="flex-end">
            {showField && (
              <Control
                gridRow={4}
                name={name}
                isDisabled={isDisabled}
                isRequired={isRequired}
                type={type}
                {...controlProps}
              />
            )}
          </GridItem>
          <GridItem gridRow={5}>
            {isInvalid && (
              <FormErrorMessage gridRow={5}>{message}</FormErrorMessage>
            )}
          </GridItem>
        </React.Fragment>
      )}
    </FormControl>
  );
};
