import React from 'react';

import { DataType, OutboundWebhook } from '@m3ter-com/m3ter-api';

import { NamedRoute } from '@/types/routes';

import { createCrudRoute } from '@/routes/crud';
import { OutboundWebhooksRoute } from '@/components/routes/integrations/outbound-webhooks/OutboundWebhooksRoute';
import { OutboundWebhooksListRoute } from '@/components/routes/integrations/outbound-webhooks/OutboundWebhooksListRoute';
import { OutboundWebhookCreateRoute } from '@/components/routes/integrations/outbound-webhooks/OutboundWebhookCreateRoute';
import { OutboundWebhookEditRoute } from '@/components/routes/integrations/outbound-webhooks/OutboundWebhookEditRoute';
import { OutboundWebhookDetailsRoute } from '@/components/routes/integrations/outbound-webhooks/OutboundWebhookDetailsRoute';

export default (): NamedRoute =>
  createCrudRoute<OutboundWebhook>({
    path: 'outbound-webhooks',
    dataType: DataType.OutboundWebhook,
    element: <OutboundWebhooksRoute />,
    list: <OutboundWebhooksListRoute />,
    create: <OutboundWebhookCreateRoute />,
    edit: <OutboundWebhookEditRoute />,
    details: <OutboundWebhookDetailsRoute />,
  });
