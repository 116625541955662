import React, { useCallback, useEffect, useMemo, useState } from 'react';

import isEqual from 'lodash/isEqual';
import { Controller, useFormContext } from 'react-hook-form';
import { Text } from '@chakra-ui/react';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  SpreadsheetInput,
  SpreadsheetInputRow,
} from '@m3ter-com/ui-components';
import { FormField, FormFieldset } from '@m3ter-com/console-core/components';

import {
  formatParameterLabel,
  IntegrationConfigParameterFieldProps,
} from './shared';

export type IntegrationConfigFieldMappingParameter = Record<string, string>;

export interface IntegrationConfigFieldMappingParameterEditorProps {
  name: string;
  value?: IntegrationConfigFieldMappingParameter;
  onChange: (newValue: IntegrationConfigFieldMappingParameter) => void;
}

export type FormIntegrationConfigFieldMappingParameterEditorProps = Omit<
  IntegrationConfigFieldMappingParameterEditorProps,
  'value' | 'onChange'
>;

const EMPTY_VALUE: IntegrationConfigFieldMappingParameter = {};

export const IntegrationConfigFieldMappingParameterEditor: React.FC<
  IntegrationConfigFieldMappingParameterEditorProps
> = ({ name, value = EMPTY_VALUE, onChange }) => {
  const { t } = useTranslation();

  const spreadsheetColumnLabels = useMemo<Array<string>>(
    () => [t('forms:labels.m3terField'), t('forms:labels.externalField')],
    [t]
  );

  const getEmptySpreadsheetRow = useCallback<() => SpreadsheetInputRow>(
    () => [
      {
        type: 'string',
        value: '',
      },
      {
        type: 'string',
        value: '',
      },
    ],
    []
  );

  // Usually here, we would convert the incoming value into spreadsheet rows for the input with a useMemo
  // and then call onChange directly whenever the spreadsheet is updated.
  // We can't do that here though because it's possible to have two field mappings occupy the same name / key
  // when typing which would be impossible to track if using the value prop as the source of truth.
  // Instead, we keep an internal state of the spreadsheet rows and call the onChange when we need to.
  const [rows, setRows] = useState<Array<SpreadsheetInputRow>>(() => {
    const valueEntries = Object.entries(value);

    if (!valueEntries.length) {
      // Provide a default empty row if starting from scratch
      return [
        [
          { type: 'string', value: '' },
          { type: 'string', value: '' },
        ],
      ];
    }

    return valueEntries.map(([rowKey, rowValue]) => [
      { type: 'string', value: rowKey },
      { type: 'string', value: rowValue },
    ]);
  });

  // When the spreadsheet rows are updated, we can re-build the custom fields from the rows and call onChange if needed
  useEffect(() => {
    const newValue = rows.reduce<IntegrationConfigFieldMappingParameter>(
      (acc, row) => {
        const [keyCell, valueCell] = row;
        if (
          !!keyCell?.value &&
          typeof keyCell?.value === 'string' &&
          !!valueCell?.value &&
          typeof valueCell?.value === 'string'
        ) {
          acc[keyCell.value as string] = valueCell.value;
        }
        return acc;
      },
      {}
    );

    if (!isEqual(newValue, value)) {
      onChange(newValue);
    }
  }, [onChange, rows, value]);

  const handleRemoveSpreadsheetRow = useCallback((rowIndex: number) => {
    setRows((currentRows) => currentRows.filter((_, rI) => rI !== rowIndex));
  }, []);

  const handleAddSpreadsheetRow = useCallback(() => {
    setRows((currentRows) => [...currentRows, getEmptySpreadsheetRow()]);
  }, [getEmptySpreadsheetRow]);

  return (
    <SpreadsheetInput
      columnLabels={spreadsheetColumnLabels}
      id={name}
      onAddRow={handleAddSpreadsheetRow}
      onRemoveRow={handleRemoveSpreadsheetRow}
      onRowsChange={setRows}
      rows={rows}
      addButtonLabel={t('common:add')}
    />
  );
};

const FormIntegrationConfigFieldMappingParameterEditor: React.FC<
  FormIntegrationConfigFieldMappingParameterEditorProps
> = ({ name }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <IntegrationConfigFieldMappingParameterEditor
          name={name}
          value={value}
          onChange={onChange}
        />
      )}
    />
  );
};

export const IntegrationConfigFieldMappingParameterField: React.FC<
  IntegrationConfigParameterFieldProps
> = ({ name, schema }) => {
  return (
    <FormFieldset legend={formatParameterLabel(schema.name)} width="100%">
      {schema.description && (
        <Text mb={2} variant="annotation">
          {schema.description}
        </Text>
      )}
      <FormField
        hideLabel
        name={name}
        label=""
        control={FormIntegrationConfigFieldMappingParameterEditor}
      />
    </FormFieldset>
  );
};
