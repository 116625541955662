import React, { PropsWithChildren, ReactNode } from 'react';

import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react';

import { Button, ButtonProps } from '@m3ter-com/ui-components';

export const UsageQueryBuilderMenuButton: React.FC<ButtonProps> = (props) => (
  <Button justifyContent="start" size="sm" variant="ghost" {...props} />
);

export interface UsageQueryBuilderMenuProps {
  trigger: ReactNode;
  header?: ReactNode;
  preFooter?: ReactNode;
  footer?: ReactNode;
  isOpen?: boolean;
  onClose?: () => void;
}

export const UsageQueryBuilderMenu: React.FC<
  PropsWithChildren<UsageQueryBuilderMenuProps>
> = ({ trigger, header, preFooter, footer, isOpen, onClose, children }) => {
  return (
    <Popover isLazy isOpen={isOpen} onClose={onClose} placement="bottom-start">
      <PopoverTrigger>{trigger}</PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        {header && <PopoverHeader>{header}</PopoverHeader>}
        <PopoverBody overflowY="auto" maxH="350px">
          {children}
        </PopoverBody>
        {preFooter && <PopoverFooter>{preFooter}</PopoverFooter>}
        {footer && <PopoverFooter>{footer}</PopoverFooter>}
      </PopoverContent>
    </Popover>
  );
};
