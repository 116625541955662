import React from 'react';

import { NamedRoute } from '@/types/routes';

import { lazyWithRetry } from '@/routes/lazyWithRetry';

const LazyUsageExplorerRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "UsageExplorer" */ '@/components/routes/analytics/data-explorer/UsageExplorerRoute'
  ).then((module) => ({ default: module.UsageExplorerRoute }))
);

const LazyUsageExplorer2Route = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "UsageExplorer2" */ '@/components/routes/usage/data-explorer/UsageExplorerRoute'
  ).then((module) => ({ default: module.UsageExplorerRoute }))
);

export default (): NamedRoute => ({
  path: 'data-explorer',
  children: [
    {
      index: true,
      name: 'usage.data-explorer',
      element: <LazyUsageExplorerRoute />,
    },
    {
      path: 'v2',
      name: 'usage.data-explorer2',
      element: <LazyUsageExplorer2Route />,
    },
  ],
});
