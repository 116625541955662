import React from 'react';

import { PermissionPolicyStatement } from '@m3ter-com/m3ter-api';
import {
  FormMultiRowGridWrapper,
  JsonEditor,
} from '@m3ter-com/console-core/components';

interface PermissionStatementJsonFieldRowProps {
  value: PermissionPolicyStatement;
  canRemove: boolean;
  onChange: (value: PermissionPolicyStatement) => void;
  onRemove: () => void;
}

export const PermissionStatementJsonFieldRow: React.FC<
  PermissionStatementJsonFieldRowProps
> = ({ value, canRemove, onChange, onRemove }) => (
  <FormMultiRowGridWrapper
    columnCount={1}
    removeButtonGridRow={1}
    onRemove={canRemove ? onRemove : undefined}
  >
    <JsonEditor value={value} onChange={onChange} />
  </FormMultiRowGridWrapper>
);
