import React, { ElementType } from 'react';

import { Heading, VStack } from '@chakra-ui/react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import { useFormError } from '@m3ter-com/console-core/hooks';
import {
  FormAddRowButton,
  FormField,
  FormMultiRowGridWrapper,
  FormMultiRowWrapper,
} from '@m3ter-com/console-core/components';
import { Alert } from '@m3ter-com/ui-components';

interface Field {
  name: string;
  label: string;
  control: ElementType;
  controlProps?: Record<string, any>;
  isDisabled?: boolean | ((values: any) => boolean);
  isRequired?: boolean;
}

export interface MultiRowFieldRowProps {
  name: string;
  index: number;
  rowFields: Array<Field>;
  maxRows?: number;
}

const MultiRowFieldRow: React.FC<MultiRowFieldRowProps> = ({
  name,
  index,
  rowFields,
}) => {
  const { control } = useFormContext();
  const values = useWatch({ control, name });

  return (
    <React.Fragment>
      {rowFields.map((rowField) => {
        const isDisabled =
          typeof rowField.isDisabled === 'function'
            ? rowField.isDisabled(values)
            : !!rowField.isDisabled;

        return (
          <FormField
            key={rowField.name}
            label={rowField.label}
            hideLabel={index > 0}
            name={`${name}.${rowField.name}`}
            control={rowField.control}
            isDisabled={isDisabled}
            isRequired={rowField.isRequired}
            {...rowField.controlProps}
          />
        );
      })}
    </React.Fragment>
  );
};

export interface MultiRowFieldProps {
  name: string;
  label: string;
  rowFields: Array<Field>;
  allowRemoveFirstRow?: boolean;
  maxRows?: number;
  emptyContentMessage?: string;
}

export const MultiRowField: React.FC<MultiRowFieldProps> = ({
  name,
  label,
  rowFields,
  allowRemoveFirstRow = false,
  maxRows = Infinity,
  emptyContentMessage,
}) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });
  const { isInvalid, message } = useFormError(name);

  return (
    <VStack spacing={4} my={8} alignItems="start">
      <Heading as="h3" size="sm">
        {label}
      </Heading>
      {isInvalid && message && <Alert status="error">{message}</Alert>}
      <FormMultiRowWrapper
        hasFields={fields.length > 0}
        emptyContentMessage={emptyContentMessage}
      >
        {fields.map((field, index) => (
          <FormMultiRowGridWrapper
            key={field.id}
            columnCount={rowFields.length}
            onRemove={
              allowRemoveFirstRow || index > 0
                ? () => {
                    remove(index);
                  }
                : undefined
            }
          >
            <MultiRowFieldRow
              key={field.id}
              name={`${name}.${index}`}
              index={index}
              rowFields={rowFields}
            />
          </FormMultiRowGridWrapper>
        ))}
      </FormMultiRowWrapper>
      <FormAddRowButton
        maxRows={maxRows}
        isDisabled={fields.length >= maxRows}
        onAdd={() => {
          append({});
        }}
      />
    </VStack>
  );
};
