import React, { useCallback } from 'react';

import { HStack, Spinner, Text, useDisclosure } from '@chakra-ui/react';
import { Building2Icon, PlusIcon, XIcon } from 'lucide-react';

import { DataType, Id } from '@m3ter-com/m3ter-api';
import { IconButton, SearchBar, TagButton } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import useEntityNamings from '@/hooks/util/useEntityNamings';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert';
import { UsageQueryBuilderSection } from '@/components/features/usage/query-builder/UsageQueryBuilderSection';
import { UsageQueryBuilderMultiSelectMenu } from '@/components/features/usage/query-builder/UsageQueryBuilderMultiSelectMenu';

import useUsageQueryBuilderAccounts from './useUsageQueryBuilderAccounts';

export interface UsageQueryBuilderAccountsProps {
  value: Array<Id>;
  onChange: (value: Array<Id>) => void;
}

export const UsageQueryBuilderAccounts: React.FC<
  UsageQueryBuilderAccountsProps
> = ({ value, onChange }) => {
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(DataType.Account);

  const {
    isLoadingOptions,
    isLoadingAccounts,
    isFetchingAccounts,
    error,
    accounts,
    search,
    options,
  } = useUsageQueryBuilderAccounts(value);

  const { isOpen, onClose, onToggle } = useDisclosure();

  const onRemove = useCallback(
    (accountId: Id) => {
      onChange(value.filter((v) => v !== accountId));
    },
    [onChange, value]
  );

  const onRemoveAll = useCallback(() => {
    onChange([]);
  }, [onChange]);

  const onAdd = useCallback(
    (ids: Array<Id>) => {
      onChange([...value, ...ids]);
      onClose();
    },
    [onChange, value, onClose]
  );

  return (
    <UsageQueryBuilderSection
      title={entityNamings.plural}
      icon={Building2Icon}
      colorScheme="cyan"
    >
      {error && (
        <ErrorAlert
          title={t('errors:generic.problemLoadingData', {
            entityName: entityNamings.pluralLower,
          })}
          error={error}
        />
      )}
      <HStack wrap="wrap">
        {value.length === 0 && (
          <Text fontStyle="italic">
            {t('features:usage.queryBuilder.allAccounts')}
          </Text>
        )}
        {isLoadingAccounts ? (
          // On the initial load show a spinner.
          <Spinner size="sm" />
        ) : (
          value.map((accountId) => {
            const account = accounts.find((a) => a.id === accountId);

            // If we don't have an account and are currently fetching
            // it's likely this account is being added. Show a spinner.
            if (!account && isFetchingAccounts) {
              return <Spinner key={accountId} size="sm" />;
            }

            return (
              <TagButton
                key={accountId}
                size="sm"
                colorScheme="cyan"
                closeLabel={t('common:remove')}
                onClose={() => {
                  onRemove(accountId);
                }}
              >
                {account ? account.name : accountId}
              </TagButton>
            );
          })
        )}
        {value.length > 0 && (
          <IconButton
            size="sm"
            colorScheme="cyan"
            variant="subtle"
            aria-label={t('common:removeAll')}
            onClick={onRemoveAll}
            icon={<XIcon size={16} />}
          />
        )}
        <UsageQueryBuilderMultiSelectMenu
          allowManualEntry
          isOpen={isOpen}
          onClose={onClose}
          trigger={
            <IconButton
              size="sm"
              colorScheme="cyan"
              variant="subtle"
              aria-label={t('forms:buttons.addEntity', {
                entityName: entityNamings.singularLower,
              })}
              icon={<PlusIcon size={16} />}
              onClick={onToggle}
            />
          }
          header={
            <SearchBar
              placeholder={t('common:searchEntity', {
                entity: entityNamings.pluralLower,
              })}
              onSearch={search}
              onClear={() => {
                search('');
              }}
            />
          }
          isLoading={isLoadingOptions}
          emptyMessage={t('common:searchEntityNoResults', {
            entity: entityNamings.pluralLower,
          })}
          options={options}
          onAdd={onAdd}
        />
      </HStack>
    </UsageQueryBuilderSection>
  );
};
